$ex-navigation-item-button-left-space: 30px;

//Afin d'éviter que le scroll apparaisse quand un composant menu n'a pas assez de place pour afficher son menu.
html {
    overflow: hidden !important;
}

body {
    overflow: hidden;
}

//Ceci est utile pour le mode expansion d'une carte (plein écran) : fullscreen.service
[ui-view="content"] {
    position: relative;
}

md-sidenav .md-default-theme,
md-sidenav,
md-sidenav.md-default-theme md-content,
md-select-menu md-content,
md-menu-content,
md-sidenav md-content {
    background-color: white;
}

md-dialog.md-dialog-fullscreen {
    max-width: 100% !important;
    max-height: 100%;
}

.md-button.md-raised {
    md-progress-circular.ex-busy-loader {
        float: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: inline;
    }

    [ex-busy] {
        position: relative;
        top: 0;
    }
}

//Normalisation des input container
md-input-container {
    margin: 10px 0px;
}

// Normalisation des icons dans le input quand ils sont focused
md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused md-icon, md-input-container:not(.md-input-invalid).md-input-focused md-icon {
    color: $black;
}

// Normalisation des selects non focused
.md-select-value .md-select-icon md-icon, md-input-container:not(.md-input-focused) {
    color: rgba(0, 0, 0, 0.54);
}

// Normalisation des selects quand ils sont focused
md-select.md-default-theme:not([disabled]):focus .md-select-value, md-select:not([disabled]):focus .md-select-value {
    border-bottom-color: #00ACC1;
    color: $black;
}

// Normalisation des selects quand ils sont dans un etats normal
.md-select-value, md-input-container:not(.md-input-focused) {
    .md-text {
        color: $black;
    }
}

.md-select-menu-container.md-active md-select-menu > * {
    overflow-x: hidden;
}

//Normalisation de l'épaisseur des lignes des composants "colonnes" et "filtres"
md-menu-item, md-option {
    height: 38px;
    min-height: 38px;
}

md-menu-item:focus:before, md-option:focus:before {
    @extend .ex-liste-focus-ligne;
}

md-select-menu.md-default-theme md-content md-option[selected]:focus,
md-select-menu md-content md-option[selected]:focus,
md-select-menu.md-default-theme md-content md-option[selected].md-focused,
md-select-menu md-content md-option[selected].md-focused {
    color: $black;
}

md-select-menu.md-default-theme md-content md-option[selected], md-select-menu md-content md-option[selected] {
    color: $black;
}

.md-select-menu-container {
    md-select-menu {
        overflow-x: hidden;
    }
}

.md-open-menu-container  {
    .ex-menu-item-button md-icon {
        margin-right: 10px;
    }
}

md-icon {
    user-select: none;
}

.ex-liste-focus-ligne {
    content: '';
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    position: absolute;
}

.ex-smaller-button {
    width: 34px !important;
    height: 34px !important;
    line-height: 24px !important;
    padding: 0 !important;
    margin-top: -5px !important;
    margin-bottom: -5px !important;
    margin-right: 0px !important;
    min-height: 24px;
    vertical-align: top;
}

.ex-occurrence-titre-code {
    font-size: 12px;
    color: $blue-grey-500;
    padding-left: 6px;
}

.navigation--open {
    ex-multi-occurrence-analyse .ex-grid-row {
        border-left: none;
    }
}

md-tabs-canvas {
    height: 40px;
}

md-pagination-wrapper {
    height: 40px;
}

md-ink-bar {
    height: 2px;
}

ex-grid:not(.ex-selection-multiple-advanced-grid) {
    .ex-grid-menu-actions .md-button {
        padding: 4px !important;
    }

    .ex-grid-col {
        height: 100%;
        line-height: 18px !important;

        &:not(.ex-grid-col-menu-forage) {
            padding: 8px 16px;
        }
    }

    .ex-grid-col.ex-grid-col-menu-forage {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .ex-data-value-container {
        align-items: center;
        position: relative;

        .ex-data-value-content {
            &.ex-data-value-with-forage-content {
                margin-top: auto;
                margin-left: 0 !important;
                max-width: calc(100% - 36px);
                float: left;
            }
        }

        .ex-data-value-bouton-forage {
            float: right;
        }
    }

    .ex-data-value-bouton-forage {
        md-icon:not(.ng-hide) {
            display: block !important;

            span {
                display: inline-block;
            }
        }
    }

    .ex-grid-col-total {
        line-height: 36px;
    }

    .ex-grid-row-totaux {
        min-height: 36px !important;
        max-height: 36px !important;
    }

    .ex-grid-col-content-actions {
        padding-left: 4px;
        padding-right: 4px;
    }

    .ex-grid-col-content-data-value {
        height: 100%;
    }

    .ex-grid-body .ex-grid-checkbox .md-icon, .ex-grid-head .ex-grid-checkbox .md-icon {
        width: 16px !important;
        height: 16px !important;
    }

    .ex-grid-head .ex-grid-checkbox {
        margin: 0 0 2px 18px !important;
    }
    ex-card .ex-grid-head .ex-grid-checkbox {
        margin: 0 0 2px 15px !important;
    }

    .ex-grid-col-wrapper {
        min-height: 24px !important;
    }

    .ex-grid-menu-actions {
        margin-top: 0;
    }

    .ex-grid-menu-actions .md-button,
    .ex-grid-col-menu-forage .md-button {
        min-height: 0;
        padding: 6px;
        width: auto;
    }

    .ex-grid-checkbox {
        .ex-grid-head & {
            margin: 15px 0 15px 15px !important;
        }
    }

    .ex-grid-body {
        .ex-grid-checkbox {
            display: block;
            margin: 6px 0 0 0;
            padding: 3px;
        }
    }
}

.ex-data-bottom-padding {
    padding-bottom: 4px !important;
}
.ex-data-top-padding {
    padding-top: 4px !important;
}

ex-card-message ex-card .ex-card-message-header {
    padding-top: 8px;
    padding-bottom: 8px;
}
.ex-card-message-avertissement-checkbox {
    .md-icon {
        width: 16px !important;
        height: 16px !important;
        top: 1px;
    }
}
.ex-card-message-avertissement-checkbox.md-checked .md-icon:after {
    left: 2.66667px;
    top: -1px;
    width: 6.66667px;
    height: 12.33333px;
}

ex-card-message {
    ex-card {
        .ex-card-message-liste {
            padding: 4px 16px;
            border-top: 1px solid #ddd;
            line-height: 2;
        }
    }
}

md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card .md-actions:not(.layout-column) .md-button.ex-button-icon-action:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.ex-button-icon-action:last-of-type {
    margin-right: 6px;
}

.ex-recherche-menu-actions-dans-entete .ex-recherche-menu-actions--menu-more {
    margin-right: 0px;
}

.ex-mono-occurrence-ecran-dashboard-card ex-menu .md-icon-button, .ex-mono-occurrence-ecran-dashboard-card ex-menu .ex-button-icon-action.md-button {
    padding-right: 8px !important;
    margin-right: 0 !important;
}

button.md-button.md-icon-button .md-ripple-container .md-ripple,
a.md-button.md-icon-button .md-ripple-container .md-ripple {
    background-color: $black !important;
    border-color: $black !important;
}

.ex-image-inline {
    width: auto;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .ex-data-value-content {
        white-space: normal;
    }

    img {
        max-height: 100%;
        width: auto;
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

ex-navigation-item ex-navigation-item .ex-navigation-item-group a.ex-navigation-item-button {
    padding-left: 12 * 1 + $ex-navigation-item-button-left-space;

    ex-navigation-item & {
        padding-left: 12 * 2 + $ex-navigation-item-button-left-space;

        ex-navigation-item & {
            padding-left: 12 * 3 + $ex-navigation-item-button-left-space;

            ex-navigation-item & {
                padding-left: 12 * 4 + $ex-navigation-item-button-left-space;

                ex-navigation-item & {
                    padding-left: 12 * 5 + $ex-navigation-item-button-left-space;

                    ex-navigation-item & {
                        padding-left: 12 * 6 + $ex-navigation-item-button-left-space;
                    }
                }
            }
        }
    }
}

// Classes de la librairie angularjs-dragula.
.gu-mirror {
    pointer-events: none;
}

// Corrections depuis la mise à jour à angular-material 1.2.0
md-radio-button {
    margin-bottom: 16px !important; 
}

md-checkbox  {
    min-height: initial;

    .md-container {
        top: initial;
        left: initial;
        right: initial;
        margin: initial;
        margin-top: initial;
        transform: unset;
    }
}

md-tooltip {
    max-width: 400px;
    word-break: break-word;
    height: auto !important;
    max-height: 70px;
    white-space: normal !important;
}