ex-recherche-menu-actions {
    position: relative;
    display: block;

    .md-fling:not(.md-is-open) .md-fab-action-item {
        transform: translateY(-40px) scale(.5);
        opacity: 0;
    }
}

ex-recherche-menu-actions[disabled]:hover {
    cursor: default;
}

.ex-recherche-menu-actions--loading {
    margin: auto 16px auto 0;
}

.ex-recherche-menu-actions-button, .ex-recherche-menu-actions-edition-rapide {
    button md-icon {
        color: $blue-grey-500;
    }

    button[disabled] md-icon {
        color: $grey-secondaire !important;
    }
}

.ex-recherche-menu-actions-dans-entete {
    .ex-recherche-menu-actions--menu-more {
        margin-right: 12px;
    }
}

.ex-recherche-menu-actions-edition-rapide-fab-action {
    background: $blue-grey-400 !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    &:not([disabled]) md-icon {
        color: #fff !important;
    }


    &:hover {
        background: $blue-grey-600 !important;
    }
}

.ex-recherche-menu-actions-edition-rapide-fab-loader {
    margin-left: 3px;
    margin-top: 0px;
}

button.md-button.ex-recherche-menu-actions-tri-icon {
    margin: -10px;
    padding: 0;

    md-icon {
        transition: transform 0.3s ease-in-out;
    }
}

.ex-recherche-menu-actions-tri-icon--asc md-icon {
    transform: rotate(-180deg);
}
