ex-case-edition-assistee {
    margin-top: 8px;
    display: flex;
}

.ex-case-edition-assistee {
    display: inline-block;
    white-space: normal !important;
    word-wrap: normal !important;
}

.ex-case-edition-assistee--readonly {
    margin-top: -8px;
    white-space: normal !important;
    word-wrap: normal !important;
    align-content: center;
}

.ex-case-edition-assistee-formulaire-input {
    display: block;
    line-height: 2;

    .ex-formulaire-input-champ {
        width: 100%;
        min-width: 0 !important;

        .ex-input-number input {
            text-align: center;
        }
    }

    md-input-container {
        margin: 0 !important;
    }

    ng-messages, ng-message {
        display: block;
        line-height: 1.3 !important;
    }

    .md-label, label {
        display: none;
    }
}
