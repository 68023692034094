.ex-dialog-champ-selection-multiple-container {
  width: 700px;
  height: 700px;
}

.ex-dialog-champ-selection-multiple-lov {
  display: block;
  width: 90%;
  margin-left: 20px;
}

@media screen and (max-height: 800px) {
  .ex-dialog-champ-selection-multiple-scroll ex-multi-occurrence {
    overflow-y: hidden !important;
  }
}

.ex-dialog-champ-selection-hidden {
  display: none;
}