.ex-menu-divider {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
}

.ex-menu-item-bouton.md-button:not([disabled]) {
    &.md-focused .md-ripple-container {
        background-color: transparent !important;
    }

    & .md-ripple {
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.ex-menu-bouton-texte {
    margin: 0px;
    height: 48px;
    font-size: 20px;
    text-transform: capitalize !important;
    border-radius: 2px;
}

.ex-menu--menu-item {
    padding: 0;
    margin: 0;

    div.ex-menu-item {
        height: 100%;
    }

    md-progress-circular {
        margin-right: 8px;
    }

    button:not([disabled]).md-button, a:not([disabled]).md-button, span:not([disabled]).md-button {
        color: rgba(0, 0, 0, 0.87) !important;
        &, * {
            cursor: pointer; 
        }
    }

    span.md-button {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        > label {
            display: inline-block;
        }
    }

    button.md-button, a.md-button, span.md-button > label {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 16px 0 16px;
        font-weight: normal;
        text-transform: none;
        text-align: left;

        i.fa, i.fab, i.fas {
            vertical-align: middle;
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 16px auto -3px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    md-icon {
        margin: auto 8px auto 0;
    }
}

.ex-menu-titre-aligne-droite {
    md-icon {
        margin-top: 6px;
        margin-right: 2px;
        float: left;
    }
}

.ex-menu--content {
    max-height: 500px;
    overflow-y: hidden;
}
