.dialog-info {
    min-width: inherit;

    .ex-dialog-md-toolbar-tools-titre {
        padding-left: 0px;
    }

    .md-transition-in {
        transition: inherit;
        transform: inherit;
    }

    .ex-dialog-info-description {
        white-space: pre-wrap;
    }
}