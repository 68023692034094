ex-formulaire-input {
    flex: 1;

    input[readonly] {
        border-bottom: 0;
    }

    md-checkbox>.md-container>div.md-ripple-container {
        display: none;
    }

    ex-template {
        flex: 1;
    }

    ex-menu-item md-icon.ex-menu-item-icon--no-action {
        display: inline-flex !important;
    }

    md-radio-button {
        &:focus {
            outline: none !important;
        }
    }

    .ex-texte-bold .md-body-1 {
        font-weight: 700;
    }
}

.md-errors-spacer {
    display: none;

    md-input-container.ex-input-string:not(.ex-input-string-count-disabled) &,
    md-input-container.ex-input-description:not(.ex-input-description-count-disabled) &,
    md-input-container.ex-input-imputation:not(.ex-input-imputation-count-disabled) & {
        display: block;
    }
}

.ex-formulaire-input {
    display: block;
    min-width: 80px;
}

.ex-formulaire-input-divider {
    border-top: solid 1px $grey-pale;
}

.ex-formulaire-input-divider-container {
    height: 39px;
}

.ex-formulaire-input-champ-date-heure {
    margin-left: -16px;
    height: 100%;
}

.ex-formulaire-input-champ {
    height: 100%;

    md-checkbox {
        margin-left: 2px !important;
        margin-top: 7px;
        margin-bottom: 10px;

        div.md-container {
            top: 10px !important;
        }
    }

    md-checkbox .md-container {
        width: 18px !important;
        height: 18px !important;
    }

    md-checkbox .md-icon {
        width: 18px !important;
        height: 18px !important;

        &:after {
            left: 4px !important;
            top: -1px !important;
        }
    }
}

.ex-formulaire-input-groupe-conditionnel {
    margin-left: -16px;
    margin-right: -16px;
}

.ex-formulaire-input-radio-group[readonly] {
    color: $black;
}

.ex-formulaire-input-groupe-accordeon {
    height: auto !important;
}

.ex-formulaire-input-accordeon-content {
    margin-bottom: -22px;
    padding-top: 8px;
    display: block;
}

.ex-formulaire-input-groupe-titre {
    width: 100%
}

.ex-formulaire-input-groupe-container {
    display: block;

    ex-formulaire-input-list {
        display: block;
        padding-top: 6px;
    }
}

.ex-formulaire-input-radio-bouton-container {
    width: 100%;
}

.ex-formulaire-input-radio-bouton-raised.md-whiteframe-z2 {
    margin-bottom: 8px;
    padding: 24px 16px 8px;
}

.ex-formulaire-input-template-slot ex-template {
    max-width: 100%;

    ex-card {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.ex-formulaire-input-radio-bouton[layout="row"] {
    md-radio-button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ex-formulaire-input-radio-bouton-menu-item .md-button {
    margin-top: -10px;
    margin-left: -16px;
}

.ex-formulaire-input-texte-menu-item .md-button {
    margin-top: -10px;
    margin-left: 0;
}

.ex-formulaire-input-description-html-text {
    text-align: justify;
    word-spacing: 1px;
    letter-spacing: 1px;
    hyphens: auto;
    widows: 2;
    orphans: 2;

    display: inline-block;
    padding: 15px 15px;
    background-color: #f9f9f9;

    white-space: normal;
    word-wrap: break-word;
}