.ex-splash-screen {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 99999;
    transition: all linear 0.2s;
}

.ex-splash-screen-wrapper {
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);

    margin: 0 auto;
    text-align: center;
}

.ex-splash-screen-logo {
    margin-left: auto;
    margin-right: auto;
}

.ex-splash-screen-logo-img {
    max-width: 75%;
    width: 400px;
    vertical-align: inherit;
}

.ex-splash-screen-animation-circulaire {
    position: relative;
    height: 100px;
    width: 100px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.ex-splash-screen-animation-1 {
    left: 50px;
    opacity: 0.4;
    animation-name: spin1;
    animation-duration: 2600ms;

    path {
        z-index: 1000;
    }
}

.ex-splash-screen-animation-2 {
    left: -52px;
    opacity: 0.6;
    animation-name: spin2;
    animation-duration: 900ms;

    path {
        z-index: 1100;
    }
}

@keyframes spin1 {
    from {
        transform: rotate(-270deg);
    }
    to {
        transform: rotate(90deg);
    }
}

@keyframes spin2 {
    from {
        transform: rotate(-180deg);
    }
    to {
        transform: rotate(180deg);
    }
}
