ex-hierarchie-item {
    min-height: 40px;

    &:focus {
        outline: none;
    }

    .ex-toggle-icon {
        margin-left: 8px;
        margin-right: 8px;
        padding: 4px;
        width: 24px;
        height: 24px;
    }

    .ex-toggle-box-header-bouton-icon {
        padding-right: 0 !important;
        min-height: 40px;
    }

    button.ex-toggle-box-header:disabled {
        &:hover {
            background-color: rgba(158, 158, 158, 0.2);
        }

        :not(.ex-couleur-texte-second) .ex-data-value-content {
            color: $black;
        }

        .ex-couleur-texte-second .ex-data-value-content {
            color: $grey-medium;
        }

        ex-toggle-icon {
            display: none;
        }
    }

    button.ex-toggle-box-header {
        box-sizing: border-box;

        &[disabled] {
            cursor: pointer;
        }

        &:not(:hover) {
            .ex-hierarchie-item-row-actions {
                visibility: hidden;
            }
        }
    }
}

.ex-hierarchie-item--active-row {
    button.ex-toggle-box-header {
        background-color: rgba(158, 158, 158, 0.2);
        font-weight: 600;
    }

    .ex-hierarchie-item-row-actions {
        visibility: visible !important;
    }

    .ex-hierarchie-item:not(.ex-hierarchie-item--active-row) {
        button.ex-toggle-box-header {
            font-weight: normal;
        }

        &:not(:hover) {
            button.ex-toggle-box-header {
                background-color: initial;
            }

            .ex-hierarchie-item-row-actions {
                visibility: hidden !important;
            }
        }
    }
}

.ex-hierarchie-item-niveau {
    width: 16px;
}

.ex-hierarchie-item-header {
    font-size: 14px;
    font-weight: normal;

    .ex-data-value-content {
        line-height: 40px;
    }
}

.ex-hierarchie-item-header-padding {
    height: 35px;
}

.ex-hierarchie-space-filler {
    border-bottom: thin dotted $grey-medium;
    margin-bottom: 16px;
    margin-right: 8px;
}

.ex-hierarchie-expandable-bloc {
    width: 100%;
    position: absolute;
}

.ex-hierarchie-expandable-bloc-content {
    margin-left: 15px;
}

.ex-hierarchie-item-menu-separator {
    border-width: 5px;
}

.ex-hierarchie-item-toggle-space-holder {
    width: 56px;
    height: 40px;
}

.ex-hierarchie-item-data-separateur {
    padding-left: 4px;
    padding-right: 4px;
}

.ex-setting-global-densite {
    .ex-hierarchie-item-toggle-space-holder {
        width: 50px;
    }

    ex-hierarchie-item {
        .ex-toggle-box-header-bouton-icon {
            margin-right: 2px;
            line-height: 40px;
        }
    }

    .ex-hierarchie-item-data {
        margin-right: 4px;
    }
}

.ex-hierarchie-item-row-actions button.md-icon-button.md-button {
    margin-left: 12px;
}

.ex-hierarchie-icon-interdit {
    color: red !important;
}