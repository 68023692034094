ex-panneau-page {
    display: flex;
    overflow: visible;
    max-width: 1200px;

    .ex-tabs-content {
        max-height: calc(100% - 41px);
        min-width: 320px;
    }

    md-sidenav {
        overflow: visible;
    }

    .md-sidenav-backdrop {
        z-index: 57;
    }

    ex-navigation-menu {
        margin-top: 8px !important;
    }

    md-tabs {
        margin-left: -1px;
        background: $white;
    }

    md-content {
        overflow: hidden;

        &:hover {
            overflow-y: auto;

            body:not(.mobile) & {
                &::-webkit-scrollbar {
                    width: 8px;
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #bdbdbd;
                }
            }
        }
    }
}

.ex-panneau-page {
    display: block;
    min-width: 320px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-top-width: 0px;
    border-bottom-width: 0px;

    .navigation-resizer {
        width: 3px;
        height: 100%;
        float: left;
        cursor: ew-resize;
        z-index: 1;
    }
}

ex-panneau-page-content {
    display: block;
    width: 320px - 32px;
}

ex-multi-occurrence-analyse,
ex-multi-occurrence-maitre-details-ecran {
    md-tab-item {
        padding-top: 18px !important;
    }
}

.ex-panneau-page-width {
    width: 300px;
}