ex-button-historique {
    font-size: 14px;

    md-icon {
        color: $blue-grey-500;
    }

    ex-data & {
        position: relative;
        top: -2px;
    }
}

/* Temporaire en attendant que ça deviennent un bouton */
.ex-button-historique-icon {
    line-height: 24px;
}

.ex-button-historique--simple {
    button {
        position: relative;
        top: -8px;
        padding: 0 !important;
        margin-bottom: -16px !important;
    }
}
