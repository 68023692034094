.ex-toggle-icon {
    color: $blue-grey-500;
    transition: transform 0.3s ease-in-out;
    .ex-accordeon & {
        color: #FFFFFF;
    }
}

.ex-toggle-icon--opened {
    transform: rotate(-180deg);
}
