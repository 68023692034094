ex-multi-occurrence-analyse {
    display: flex;
    position: relative;
    min-height: 100%;
    height: 100%;
    flex: 1;

    // Pour l'espace du scroll
    .ex-grid-head, .ex-grid-row-totaux {
        .ex-visible-scroll & {
            padding-right: 17px;
        }
    }

    .ex-multi-occurrence-content {
        overflow-x: hidden;
    }

    .ex-multi-occurrence-zone-criteres {
        padding-top: 2px !important;
    }

    .ex-multi-occurrence-etats {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .ex-multi-occurrence-actions {
        margin-top: -16px;
        margin-bottom: 8px !important;
        padding-left: 16px;
        padding-right: 16px;
    }

    .ex-grid-row {
        border-left: 1px solid #ddd;
        display: block;
    }

    ex-grid {
        display: flex;
    }

    .ex-grid {
        .ex-grid-body {
            display: flex;
            overflow-y: scroll;
            overflow-x: hidden;
            flex: 100;
            max-height: 100%;
            flex-direction: column;
        }
    }

    ex-erreur-chargement {
        margin-top: 40px;
    }
    .ex-formulaire-form,
    .ex-formulaire-custom-content {
        display: block;
    }

    .ex-accordeon {
        padding-left: 10px;
        padding-right: 8px;
        width: 100%;
        display: block;
        box-sizing: content-box;
        margin-left: -9px;
    }
    
    .ex-multi-occurrence-ecran-container-bouton-action-wrapper {
        width: 100%;
        z-index: 8;
        background-color: #FAFAFA;

        .ex-multi-occurrence-ecran-container-bouton-action > div {
            margin-left: auto;
        }
    }
}

.ex-multi-occurrence-analyse-container {
    overflow: hidden;
}

.ex-multi-occurrence-analyse-loader {
    margin: 16px auto;
}

.ex-multi-occurrence-analyse--has-inline-multi {
    .ex-saisie-content {
        overflow: hidden;
    }
}

.ex-grid-row {
    &:hover {
        .ex-multi-occurrence-analyse--inline-details {
            ex-menu.ex-recherche-menu-actions--menu-more md-icon:not(:hover) {
                @extend .ex-blue-grey-500;
            }
        }
    }

    .ex-multi-occurrence-analyse--inline-details {
        ex-multi-occurrence-advanced, ex-grid.layout-fill {
            margin: 0 !important;
            width: 100% !important;
        }

        ex-card-multi-advanced {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .ex-multi-occurrence-content {
            flex: none !important;
        }

        .ex-grid {
            display: block;
            height: auto;

            .ex-grid-body {
                display: block;
                flex: none;
                height: auto;
            }

            .ex-grid-row {
                border-left: none;
                border-right: none;

                &:last-of-type {
                    border-bottom: none;
                }
            }

            .ex-grid-row:not(.ex-grid-row--opened) {
                font-weight: normal;

                .ex-grid-menu-actions {
                    visibility: hidden;
                }

                .ex-grid-col {
                    font-weight: normal;
                }

                &:hover {
                    .ex-grid-menu-actions {
                        visibility: visible;
                    }
                }
            }
        }
    }
}
