body.ex-setting-global-densite ex-mono-occurrence {
    .ex-data-top-padding,
    .ex-data-bottom-padding {
        padding: 0 !important;
    }

    md-card > div > md-card-content {
        padding-bottom: 8px;
    }

    .md-input-has-value label {
        font-weight: bold;
        color: #000;
    }
}
