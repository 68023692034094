ex-filtre {
    ex-select-colonne md-input-container {
        margin-top: 10px;
    }

    .ex-input-lov-input-wrapper {
        margin-top: -17px;
        padding-top: 13px;
        padding-bottom: 0px;
    }

    .ex-input-lov-target {
        margin-top: 3px;
    }

    .ex-input-lov-description {
        top: 5px;
    }

    ex-input-lov {
        md-input-container {
            margin-bottom: 11px;
        }
    }

    ex-select-colonne {
        width: 35%;
        padding-left: 13px !important;

        .md-select-value {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .ex-filtre--criteres-suggeres {
        ex-select-colonne {
            padding: 0 !important;
            margin: 0 16px;
            width: 50%;
            flex: inherit;
        }
    }

    ex-input-date-hour {
        .md-datepicker-input-container {
            margin-left: 0 !important;
        }

        .md-datepicker-button {
            margin-left: 0 !important;
        }

        ex-input-hour {
            input {
                min-width: 60px;
            }
        }
    }
}

.ex-filtre-operateur {
    font-size: 16px;
}

.ex-filtre-radio-label {
    transform: translate3d(0, 6px, 0) scale(0.90);
    -webkit-font-smoothing: antialiased;
    transform-origin: left top;
    position: absolute;
    bottom: 100%;
    margin-bottom: -18px;
    left: 0;
    right: auto;
    color: rgba(0, 0, 0, 0.54);

    &:after {
        content: ' *';
        font-size: 13px;
        vertical-align: top;
        color: $red-500 !important;
    }
}

.ex-filtre-radio-label-container {
    position: relative;
}

.ex-filtre-boutons-radio {
    margin-top: -8px;

    md-radio-button:last-of-type {
        margin-bottom: 24px !important;
    }
}
