._md-datepicker-floating-label._md-datepicker-has-calendar-icon {
    ex-input-validation {
        .md-input-message-animation {
            margin-left: 0;
        }
    }
}

ex-input-validation {
    .ex-input-validation-error {
        color: $red-500 !important;
    }

    .ex-input-validation-warning {
        color: $orange-500 !important;
    }

    .ex-input-validation-info {
        color: $blue-500 !important;
    }

    ng-messages:not(.md-auto-hide) {
        position: inherit !important;
        ng-message {
            display: block;
        }
    }

    ng-message {
        padding-top: 2px !important;
    }
}
