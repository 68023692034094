ex-dashboard-card {
    flex-shrink: 0;
}

.ex-dashboard-card-bas {
    display: block;

    &:empty {
        display: none;
    }
}

.ex-dashboard-card-grille-texte-valeur {
    .ex-dashboard-card-entete {
        text-align: right;
    }

    ex-data-value {
        padding-left: 8px !important;
        text-align: right;
    }

    .ex-dashboard-card-bas {
        margin: 0 !important;
    }
}
