.ex-dialog-edition-avancee-dialog {
    overflow: hidden;
}

.ex-dialog-edition-avancee {
    width: calc(100vw - 32px) !important;
    max-width: 1200px;
    height: calc(100vh - 32px) !important;

    .ex-dialog-content, .ex-dialog-div-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 100%;
    }

    md-dialog-content {
        overflow: hidden;
        padding-top: 8px;
        padding-bottom: 7px;
    }

    ex-card-multi-template {
        // Entête de carte + margin
        min-height: 40px + 8px + 8px;
        max-height: 100%;
    }

    md-card {
        margin-bottom: 9px !important;
    }

    md-card-title {
        flex-grow: 0;
        flex-shrink: 0;
    }

    ex-card, ex-card-multi-template, .ex-card-content-container:not(.ex-expandable-target--collapsed), md-card-content {
        display: flex;
        flex-direction: column;
    }

    md-card-content {
        overflow-y: auto;
    }

    ex-card, md-card, .ex-card-content-container {
        height: 100%;
        max-height: 100%;
        min-height: 0;
        flex-basis: auto;
    }

    .ex-multi-occurrence-template-loader--datalist-visible {
        left: calc(50% - 25px);
    }
}

.ex-dialog-edition-avancee-loader {
    left: 50%;
    margin-left: -25px;
    margin-bottom: 16px;
}

.dialog-edition-avancee-card-input {
    .ex-card-content-container, md-card-content {
        flex: 1 1 auto;
    }
}

.dialog-edition-avancee-input {
    width: 100%;
    height: 100%;
}

.dialog-edition-avancee-bouton {
    position: absolute;
    height: 30px !important;
    width: 30px !important;
    min-height: 0;
    padding: 0 !important;
    left: 100%;
    top: calc(50% - 20px);
    border-radius: 50% !important;
}

.dialog-edition-avancee-multi-wrapper {
    position: relative;
    min-height: 57px;
}
