.navigation {
    position: relative !important;
    top: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    // Sous le backdrop du panneau de droite
    z-index: 58;

    flex: none !important;
    display: block !important;
    max-width: 500px;
    min-width: 320px;
    width: 320px;
    overflow: hidden;

    .navigation-resizer {
        width: 3px;
        height: 100%;
        float: right;
        cursor: ew-resize;
        z-index: 1;
    }
}

.navigation-menu-button {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0 6px !important;

    &.ex-navigation-menu-item--focused {
        padding-left: 2px !important;
    }

    &.navigation-menu-button--active {
        padding-left: 2px !important;
    }
}

.navigation-toggle-box > div > .ex-toggle-box-header .ex-navigation-menu-item--focused {
    padding-left: 2px !important;
}

.navigation-menu-button .ex-navigation-item-text {
    color: $black !important;
}

.navigation-menu-button--active {
    background: rgba(158, 158, 158, 0.14);
    padding-left: 2px;

    &:hover {
        background: rgba(158, 158, 158, 0.14) !important;
    }

    .ex-navigation-item-text {
        margin-left: -4px;
    }
}

.navigation-menu-button {
    text-align: left;
}

.navigation-aucun-ecran {
    padding: 8px 16px;
    font-size: 14px;
    color: $grey-medium !important;
}
