.dialog-confirm {
    min-width: inherit;

    .ex-dialog-md-toolbar-tools-titre {
        padding-left: 0px;
    }

    .md-transition-in {
        transition: inherit;
        transform: inherit;
    }
}

.dialog-confirm-customStyle {
    max-width: 460px;
}