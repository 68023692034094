//Permets d'ajouter un cercle sur une icône de 24px...
.ex-fab-icon {
    height: 36px;
    width: 36px;
    border-radius: 50%;

    md-icon {
        margin-left: 6px;
        margin-top: 4px;
        color: #FFFFFF;
    }
}

.md-fab.ex-mini {
    line-height: 24px;
    width: 24px;
    height: 24px;
    min-height: 24px;
}

.ex-button.md-button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.ex-button-icon-action.md-button {
    @extend .md-icon-button;
}

.ex-button-action.md-button {
    color: rgba(0, 0, 0, 0.38) !important;
    flex-shrink: 0;
    min-width: 30px !important;
    line-height: normal;
    display: inline-flex;

    &:not(:disabled):not(.ex-disabled--disabled) {
        color: #fff !important;
    }

    md-icon {
        color: #fff !important;
        margin-right: 5px;
    }

    .ex-busy-loader {
        margin-top: 0;
    }
}

.ex-button-secondary.md-button {
    min-width: 30px !important;

    &:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-tertiary) {
        background-color: rgb(120, 144, 156) !important;
        color: #fff !important;
    }

    &:hover:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-tertiary), &:focus:not(:disabled):not(.ex-disabled--disabled) {
        background: darken(rgb(120, 144, 156), 12%) !important;
    }

    md-icon {
        color: #fff !important;
        margin-right: -5px;
    }

    &[disabled] , &.ex-disabled--disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        cursor: default;
        box-shadow: none !important;
        color: rgba(0, 0, 0, 0.38) !important;

        md-icon {
            color: rgba(0, 0, 0, 0.38) !important;
        }
    }
}

.md-tab {
    padding-top: 8px !important;
}

.ex-button-secondary:not(.md-button) {
    .md-button {
        background-color: $blue-grey-400 !important;
        color: #fff !important;

        &:hover:not(:disabled):not(.ex-disabled--disabled), &:focus:not(:disabled):not(.ex-disabled--disabled) {
            background: darken(rgb(120, 144, 156), 12%) !important;
        }

        md-icon {
            color: #fff !important;
        }

        &[disabled] {
            background-color: rgba(0, 0, 0, 0.12) !important;
            cursor: default;
            box-shadow: none !important;
            color: rgba(0, 0, 0, 0.38) !important;

            md-icon {
                color: rgba(0, 0, 0, 0.38) !important;
            }
        }
    }
}

//style commun à la toolbar des 2 applications
.app-toolbar {
    a.md-button:not(.ex-toolbar-aide-technique--active), button.md-button:not(.ex-toolbar-aide-technique--active) {
        color: $white-normalized;

        md-icon {
            color: $white-normalized;
        }

        &:not([disabled]):focus,
        &:not([disabled]):hover {
            color: $white;
            background-color: rgba(0, 0, 0, 0.12) !important;

            md-icon {
                color: $white;
            }
        }
    }

    a.md-button.md-icon-button,
    button.md-button.md-icon-button {
        padding: 0 !important;
        margin: 0 !important;

        &:not(.ex-toolbar-aide-technique--active) md-icon {
            color: $white-normalized;
            border-radius: 50%;
        }

        &:not([disabled]):focus,
        &:not([disabled]):hover {
            background-color: transparent !important;

            &:not(.ex-toolbar-aide-technique--active) md-icon {
                color: $white;
                background-color: rgba(0, 0, 0, 0.12) !important;
            }
        }
    }

    .md-button:not([disabled]):focus {
        background-color: transparent;
    }

    button.md-icon-button.ex-toolbar-bouton-menu-sidenav {
        padding: 0px;
        margin-top: 4px !important;
    }
}

//Fullscreen
md-menu-item > .md-button {
    font-size: 14px;
}
