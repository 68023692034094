ex-input-lov {
    .ex-input-lov--disabled .ex-input-lov-description {
        color: rgba(0, 0, 0, 0.38);
        // À la place d'une vrai bordure, les input material utilise un dégradé qui forme un pointillé
        border-bottom-color: transparent;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
        background-position: bottom -1px left 0;
        background-size: 4px 1px;
        background-repeat: repeat-x;
    }

    &[readonly] label {
        // Remonte le libellé, comme lorsqu'il y a des données
        color: rgba(0, 0, 0, 0.54);
        transform: translate3d(0, 6px, 0) scale(0.90) !important;
        transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
    }
}

.ex-input-lov {
    position: absolute;
    // Position par défaut qui sera overridé
    top: -9999px;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 2px 5px $black-ombre-portee;

    .ex-data {
        padding: 2px 0 !important;

        &:last-child {
            padding-bottom: 0 !important;
        }
    }

    .ex-data-label {
        font-weight: normal !important;
        flex-grow: 20 !important;
    }
}

.ex-input-lov--required {
    label:after {
        content: ' *';
        font-size: 13px;
        vertical-align: top;
        color: $red-500 !important;
    }
}

.ex-input-lov--readonly,
.ex-input-lov--readonly.ex-input-lov--disabled {
    .ex-input-lov-input {
        visibility: hidden;
        padding-left: 0;
    }

    .ex-input-lov-description {
        border-bottom: 0;
        color: $black;
        pointer-events: all;
        cursor: text;
    }
}

.ex-input-lov--invalid {

    .ex-input-lov-description,
    .ex-input-lov-input {
        border-bottom: 2px solid $red-500 !important;
        padding-bottom: 0 !important;
    }
}

$lovSearchBoxHeigth: 53px;
$lovTargetHeight: 26px;
$lovMargeBas: 3px;

.ex-input-lov-content {
    position: relative;
    overflow: auto;
    max-height: calc(50vh - #{$lovSearchBoxHeigth} - #{$lovTargetHeight} - #{$lovMargeBas});
    min-height: 32px;

    .ex-data {
        font-size: 12px;
    }

    .ex-data-label {
        opacity: .66;
    }

    .ex-data-value {
        opacity: .38;
    }

    .ex-data-value-col {
        opacity: 1 !important;
        font-weight: 500;
    }
}

.ex-input-lov--top {
    box-shadow: 0 -2px 5px $black-ombre-portee;
}

.ex-input-lov-target {
    height: 26px;
    line-height: 19px;
    @extend .ex-couleur-texte-noir
}

.ex-input-lov-clear {
    position: absolute;
    top: -1px;
    right: 20px;
}

.ex-input-lov-input-wrapper {
    padding-bottom: 4px;

    .ex-input-lov--focused & {
        background: rgb(242, 242, 242);
    }

    .ex-input-lov-chevron:not([disabled]) {
        border-radius: 50% !important;
    }
}

.ex-input-lov-description {
    position: absolute;
    top: 6px;
    left: 2px;
    right: 2px;
    padding-top: 2px;
    padding-left: 2px;
    height: 27px;
    pointer-events: none;
    border: 0;
    display: block;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    letter-spacing: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
    @extend .ex-couleur-texte-noir;

    // Pour que les valeurs de description affiche sur 1 ligne
    div,
    span,
    ex-data-value {
        display: inline;
        white-space: nowrap;
    }

    .ex-input-lov--capturing & {
        display: none;
    }
}

.ex-input-lov-input {
    opacity: 0;
    position: relative;
    top: 1px;
    padding-right: 54px !important;

    .ex-input-lov--capturing & {
        opacity: 1;
    }
}

.ex-input-lov-loader {
    margin-top: 10px;
}

.ex-input-lov-chevron {
    position: absolute;
    right: 0;
    margin: -4px !important;
    top: 0;
}

.ex-input-lov-chevron-selection-multiple {
    position: absolute;
    right: -30;
    margin: -4px !important;
    top: 0;
}

.ex-input-selection-multiple-container {
    display: flex;
    justify-content: flex-end;
}

.ex-input-selection-multiple-titre-libelle {
    margin-right: auto;
}

.ex-input-selection-multiple-icon-container {
    margin-right: 35px;
    z-index: 3;
    transform: translateY(10px);
}

/*.ex-input-selection-multiple {
    margin-left: -160px;
    z-index: 9;
    position: fixed;
    top: 285px;
}*/

.ex-input-lov-chevron--active {
    md-icon {
        color: $deep-orange-400 !important;
    }
}

.ex-input-lov-ok {
    position: absolute;
    top: 7px;
    right: 32px;

    md-icon {
        color: rgba(0, 0, 0, 0.54) !important;
    }
}

.ex-input-lov-loader {
    position: absolute;
    right: 54px;
    top: 0;
}

.ex-input-lov-item {
    position: relative;
    outline: 0;
    min-height: 22px;

    .ex-input-lov--has-details & {
        border-bottom: 1px solid $blue-grey-200;
    }

    &:not(.ex-input-lov-item--no-result) {
        cursor: pointer;

        &:hover {
            background: $grey-200;
        }
    }
}

.ex-input-lov-details-sans-libelle {
    .ex-data-value {
        padding-left: 0 !important;
    }
}

.ex-input-lov-item--active {
    background: $grey-200;

    &:not(.ex-input-lov-footer):before {
        @extend .ex-liste-focus-ligne;
    }
}

.ex-input-lov-item-description {
    font-size: 14px;
    padding-top: 2px;

    div {
        display: inline;
    }
}

.ex-input-lov-item--no-result {
    font-style: italic;
    text-align: center;
}

.ex-input-lov-footer {
    padding: 8px;
    text-align: right;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;

    &:hover {
        background: $grey-200;
    }

    .ex-busy-loader {
        float: none;
        display: inline-block;
        position: relative;
        top: 2px;
    }
}

.ex-input-lov-hint {
    display: none;
    position: absolute;
    top: 6px;
    left: 4px;
    bottom: 26px;
    pointer-events: none;
    opacity: .65;
    letter-spacing: 0;
    padding-top: 2px;
    padding-bottom: 1px;
    border-bottom: 1px solid transparent;
    height: 30px;
    box-sizing: border-box;

    .ex-input-lov--capturing & {
        display: block;
    }
}

.ex-input-lov-hint-start {
    visibility: hidden;
}

.ex-input-lov-message {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ex-input-lov-details-coche {
    .ex-data {
        flex-direction: row-reverse;
    }

    .ex-data-label {
        max-width: 70%;
        flex-grow: 70;
        text-align: left;
        padding-right: 0 !important;
        padding-left: 8px !important;
        opacity: .38;
        color: $black !important;
    }

    .ex-data-value {
        max-width: 30%;
        flex-grow: 30;
        text-align: right;
        padding-right: 8px !important;
        padding-left: 0 !important;
        opacity: 1;
    }
}

.ex-input-lov-selection-multiple {
    background-color: white !important;
    z-index: 2;
    width: 100%;
    /*height: 80%; */
    position: absolute;
    top: 19px;
    /*opacity: 0.8;*/
}

.ex-input-lov-selection-multiple-bouton {
    background-color: rgb(0, 172, 193) !important;
    border-radius: 3px;
}

.ex-input-lov-selection-multiple-parent-div {
    position: relative;
}