@import '~dropzone/dist/dropzone.css';

.dropzone {
    border-width: 2px;
    border-style: dashed;
    border-radius: 5px;
    min-height: initial;

    .dz-message {
        margin: 0;

        >div {
            display: block;
        }
    }

    .dz-progress {
        display: none;
    }

    .dz-error-message {
        top: 145px !important;
    }
}

.ex-file-upload-erreur-message {
    color: #F44336;
}

.ex-input-file-upload-icon {
    font-size: 36px !important;
    width: 36px;
    height: 36px;
}