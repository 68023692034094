.accueil {
    overflow-y: auto;
    background-color: $grey-200;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;

    .accueil-container {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .accueil-boite-alerte {
        background-color: rgba(0, 0, 0, .75);
        color: $white;
        padding: 20px;
        margin: auto;
        width: calc(75% - 10px);
        max-width: calc(75% - 10px);
        font-family: Roboto, Arial, sans-serif !important;
        min-height: 100px;
        position: relative;

        &.accueil-boite-alerte-important {
            .accueil-boite-alerte-sujet,
            .accueil-boite-alerte-texte {
                color: $yellow-uq;
            }

            hr {
                background-color: $yellow-uq;
            }
        }

        hr {
            width: 33.333333%;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 0;
            height: 2px;
            background-color: $white;
            border: none;
        }

        .accueil-boite-alerte-sujet {
            font-size: 2em;
        }

        .accueil-boite-alerte-texte {
            font-size: 1.1em;
            text-align: justify;
        }

        .accueil-boite-alerte-buttons {
            user-select: none;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-bottom: 10px;

            md-icon {
                color: $white;
                cursor: pointer;
                font-size: 16px;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
            }
        }
    }
}

.accueil-zone-carte {
    align-items: flex-start;
}

.accueil-carte-info {
    background-color: $white;
    width: 25%;
    max-width: 25%;
    z-index: 0;
    font-size: 19px;
    padding: 25px;

    md-icon {
        font-size: 50px;
        height: 50px;
        width: 50px;
    }

    box-shadow: none;
    border-color: $grey-pale;
    border-style: solid;
}

.accueil-carte-info-texte {
    margin-top: 5px;
    text-align: center;
    font-size: 0.75em;
    width: 100%;

    .accueil-carte-info-titre {
        font-weight: bold;
        font-size: 19px;
    }

    .md-button {
        width: 100%;
        color: $white;
        margin: 0;
    }

    a {
        text-decoration: none;
        margin-bottom: 5px;
        margin-top: 5px;
        display: inline-block;
    }

    hr {
        background-color: $black;
        border: none;
        height: 1px;
        width: 33.333333%;
    }
}

/*******************/
/*    RESPONSIVE   */
/*******************/

@media (max-width: $layout-breakpoint-md) {
    .accueil-carte-info {
        max-width: calc(100% - 16px) !important;
        width: calc(100% - 16px);
        word-wrap: break-word;
    }

    .accueil-boite-alerte {
        max-width: calc(100% - 56px) !important;
        width: calc(100% - 56px) !important;
        min-height: 150px !important;
    }

    .accueil-container {
        position: relative !important;
    }
}