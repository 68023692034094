$ex-navigation-item-toggle-box-left-space: 28px;

.ex-navigation-item {
    padding-left: 0;
}

.ex-navigation-item-group,
.ex-navigation-item {
    .md-button {
        line-height: 20px;
        font-weight: normal;
        min-height: 0;
    }
}

.ex-navigation-item-group>ex-toggle-box>div>.ex-toggle-box-header {
    padding-left: 0 !important;
}

.ex-navigation-item-button:not(.navigation-menu-button) {
    padding: 0;
    text-transform: none;
    margin: 0;
    text-align: left;
    border-radius: 0;
    box-sizing: border-box;

    md-icon:not(.ex-toggle-icon) {
        width: 21px;
        height: 21px;
        font-size: 21px;
    }

    em.fas {
        margin-right: 4px;
        margin-left: 4px;
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
    }

    &:not(a) {
        padding: 0 12px 0 40px;
    }

    ex-navigation-item ex-navigation-item &:not(a) {
        padding-left: 12 * 2 + $ex-navigation-item-toggle-box-left-space;

        ex-navigation-item & {
            padding-left: 12 * 3 + $ex-navigation-item-toggle-box-left-space;

            ex-navigation-item & {
                padding-left: 12 * 4 + $ex-navigation-item-toggle-box-left-space;

                ex-navigation-item & {
                    padding-left: 12 * 5 + $ex-navigation-item-toggle-box-left-space;

                    ex-navigation-item & {
                        padding-left: 12 * 6 + $ex-navigation-item-toggle-box-left-space;

                        ex-navigation-item & {
                            padding-left: 12 * 7 + $ex-navigation-item-toggle-box-left-space;

                            ex-navigation-item & {
                                padding-left: 12 * 8 + $ex-navigation-item-toggle-box-left-space;

                                ex-navigation-item & {
                                    padding-left: 12 * 9 + $ex-navigation-item-toggle-box-left-space;

                                    ex-navigation-item & {
                                        padding-left: 12 * 10 + $ex-navigation-item-toggle-box-left-space;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ex-navigation-item-active {
    background: rgba(158, 158, 158, 0.14);

    .ex-navigation-item-text {
        margin-left: -4px;
    }
}

.ex-navigation-item-active:hover {
    background: rgba(158, 158, 158, 0.14) !important;
}

.ex-navigation-item-text-bold {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-weight: 500;
}

.ex-navigation-item-text {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $grey-medium !important;
}

.ex-navigation-item-group a.ex-navigation-item-button span.ex-navigation-item-text,
.ex-navigation-item-group a.ex-navigation-item-button span.ex-navigation-item-code {
    border-left: 1px solid #ccc;
    color: #000 !important;
    padding: 2px 12px 2px 8px;
}

.ex-navigation-item-group {
    position: relative;

    >md-icon {
        color: $yellow-uq;
        position: absolute;
        left: 6px;
        z-index: 5;
        font-size: 22px;
        cursor: pointer;
        display: inline;

        &:hover {
            display: inline !important;
        }
    }
}

.navigation-toggle-box>div>div>ex-toggle-box-content,
ex-navigation-menu-content {
    >ex-navigation-item>.ex-navigation-item-group>.ex-navigation-item-button {
        padding-left: 32px;

        >span.ex-navigation-item-text,
        & {
            border: 0 !important;
        }
    }
}