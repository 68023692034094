.dialog-cycle-evenement-informations {
    min-width: 500px !important;

    ex-cycle-list-etapes {
        margin-top: 15px;
    }

    ex-data .ex-data-label {
        margin-top: auto;
        margin-bottom: auto;
    }

    ex-data-valeur {
        color: $black;

        & > ex-menu-item {
            margin-left: -16px;
            margin-top: -8px;
        }
    }
}
