.dialog-historique-colonne {
    background-color: $white;
    width: 900px;

    .ex-dialog-div-content {
        padding: 16px 0 0 0 !important;
    }

    .ex-multi-occurrence-recherche {
        padding-right: 8px;
        padding-top: 0;
    }

    .ex-multi-occurrence-template-container {
        display: block;
    }
}
