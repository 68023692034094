.ex-bouton-nouveau.md-button {
    &.ex-button-action-plat,
    &.md-icon-button {
        .ex-disabled--disabled {
            &:hover {
                background-color: transparent !important;
            }
        }

    }

    .ex-disabled--disabled {
        background-color: inherit !important;
    }

    &[disabled] md-icon {
        color: rgba(0, 0, 0, 0.38) !important;
    }

    &:focus {
        .md-icon {
            color: rgba(0, 0, 0, 0.54) !important;
        }
    }

    display: grid;
}

.ex-bouton-nouveau-text {
    width: fit-content !important;
    display: flex !important;
}

.ex-bouton-nouveau-lbl-aide {
    white-space: nowrap;
}

body.mobile ex-bouton-nouveau > .layout-row {
    display: block;

    > * {
        display: inline-block;
    }
}
