ex-sommaire {
    position: relative;
    z-index: 1;
    display: block;
    padding-top: 8px;

    md-sidenav {
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0px;
            top: 0;
            bottom: 0;
            width: 10px;
            background: linear-gradient(to right, $black-ombre-portee-pale, $black-transparent);
            z-index: 10;
        }
    }

    &, ex-panneau-page {
        display: block;
        height: calc(100% - 40px);
    }

    .ex-panneau-page-header {
        background: transparent !important;
        padding: 8px 8px 8px 12px !important;
        height: auto !important;
        min-height: 0 !important;
    }

    ex-panneau-page-content {
        width: 320px;
    }

    .ex-input-recherche-box-recherche {
        margin-bottom: 0 !important;
    }

    ex-input-recherche {
        display: block;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
    }
}

.ex-sommaire-titre {
    font-size: 20px;
    font-weight: normal;
    margin-top: 12px;
    padding-top: 0;
}

.ex-sommaire-tab {
    padding-top: 0;
    min-width: 320px;
    padding-bottom: 16px !important;
}
