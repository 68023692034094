.ex-etats-titre {
    margin-left: 2px;
    white-space: nowrap;
}

.ex-etats-supprimer, .ex-etats-description {
    md-select & {
        display: none;
    }
}

.ex-etats-description {
    font-size: 12px;
    color: $blue-grey-400;
    padding-top: 2px;
}

.ex-etats-dropdown {
    min-width: 200px;
    padding-right: 0px;
    height: auto;
    & > .md-text {
        width: 100%;
        flex-direction: row;
    }

    .ex-etats-nom-option {
        display: inline-block;
        max-width: 500px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

.ex-etats-select {
    min-width: 200px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
