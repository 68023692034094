ex-card-multi-advanced {
    display: block;

    .ex-grid-loader {
        margin-top: 30px;
    }

    .ex-grid-no-data-in-card {
        min-height: 150px;
        max-height: 150px;
    }

    ex-multi-occurrence-advanced {
        width: calc(100% + 18px);
        // -16px de marge - 2px de bordure
        margin-left: -9px;
        position: relative;
    }

    ex-grid {
        height: auto !important;
        min-height: auto !important;
    }

    ex-grid.layout-fill {
        width: calc(100% + 14px);
        // -16px de marge - 2px de bordure
        margin-left: -7px;
    }

    .ex-multi-occurrence-zone-recherche {
        padding-top: 0px !important;
    }

    md-card md-card-content {
        padding-bottom: 0px;
        padding-top: 0;
    }

    .ex-grid-no-data-cercle {
        display: none;
    }

    .ex-grid-no-data {
        height: auto;
        padding-top: 16px !important;
        margin-bottom: 9px !important;
        border-top: 1px solid #ddd;
    }

    .ex-grid-body {
        overflow-y: hidden !important;
    }

    .ex-grid-head-espace {
        padding-right: 0 !important;
    }

    ex-pagination {
        padding: 0;
    }
}

.ex-card-multi-advanced--edition-rapide {
    md-card-content {
        padding-left: 0;
        padding-right: 0;
    }

    ex-multi-occurrence-advanced {
        margin-left: 0;
        width: auto;
    }

    ex-grid.layout-fill {
        min-width: 100% !important;
        width: auto !important;
        margin-left: 0 !important;
    }

    md-content {
        overflow-x: auto !important;
        overflow-y: hidden;
    }

    .ex-multi-occurrence-actions-filtres-container {
        margin-left: 0 !important;
    }

    .ex-formulaire-input-champ {
        flex-direction: column;
    }

    .ex-formulaire-input-champ-boolean {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -1px;

        > div {
            flex: 1;
        }
    }

    .ex-grid-col--action {
        margin-top: 0;
    }

    .ex-formulaire-message-erreur {
        padding-left: 16px;
        padding-top: 4px;
        padding-bottom: 0 !important;

        md-icon {
            max-width: 24px !important;
            box-sizing: content-box !important;
        }
    }

    .ex-formulaire-message-erreur-item:last-child {
        padding-bottom: 0 !important;
    }
}
