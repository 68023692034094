.ex-criteres-suggeres {
    background-color: $white;

    .ex-saisie-wrapper {
        display: flex;
    }
    //Dans les critères suggérés, il faut afficher un astérisque pour indiquer que la pastille de recherche
    //ne sera pas supprimable.
    .ex-checkbox-container--required {
        label:after {
            content: ' *';
            font-size: 13px;
            vertical-align: top;
            color: $red-500 !important;
        }
    }
    .ex-saisie-content {
        max-height: 35vh;
    }
}

.ex-criteres-suggeres-liste-pastille-filtre {
    margin-left: -1px;
}
