@use "sass:math";

ex-fab-speed-dial-circle {
    $from: 0;
    $to: 7;

    $angle: math.div(360, $to);
    $start: 272;
    $circle-size: 27;

    @mixin transform($rotation: 0, $translate: 20) {
        transform: rotate($rotation*1deg) translate($translate*1px) rotate(-1deg*$rotation);
    }

    @for $index from $from through $to {
        .btn-#{$index} {
            position: absolute;
            bottom: 2px;
            left: 2px;
        }

        .btn-#{$index}:hover {
            background-color: #f5f5f5;
            box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.3);
        }

        .md-is-open .btn-#{$index} {
            position: absolute;
            opacity: 1 !important;
            //animation: fadein 500ms;
            @include transform(($index * $angle) + $start, ($circle-size*1.8));
        }
    }

    .fabSpeedDialCircle .text-capitalize {
        text-transform: capitalize;
    }

    .fabSpeedDialCircle .md-fab:hover, .fabSpeedDialCircle .md-fab.md-focused {
        background-color: #f5f5f5;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.54);
    }

    .fabSpeedDialCircle .lock-size {
        min-width: 300px;
        min-height: 300px;
        width: 300px;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    md-fab-trigger {
        z-index: 100;
        .md-fab {
            box-shadow: none !important;
            margin: 0;
            md-icon {
                left: 0px;
                top: 0px;
                height: 40px;
                width: 40px;
                min-height: 40px;
                min-width: 40px;
                line-height: 40px;
                color: rgba(0, 0, 0, 0.38) !important;
                background-color: #fff;
                font-size: 24px;
                font-weight: 600;
            }
            md-icon:hover {
                background-color: #f5f5f5 !important;
                box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.3) !important;
            }
            background-color: inherit !important;
        }
        .md-fab:hover {
            background-color: #f5f5f5 !important;
            box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.3) !important;
        }
    }

    md-fab-actions {
        z-index: 50;
        .md-fab {
            margin: 0;
            opacity: 0;
        }
    }

    .md-fab-action-item {
        line-height: 36px !important;
        width: 36px !important;
        height: 36px !important;
    }

    ng-md-icon {
        display: block;
        svg {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .menu-icon, .close-icon {
        position: absolute;
        top: 8px;
        left: 8px;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        transform: scale(1);
    }

    .close-icon {
        opacity: 0;
        transform: scale(0);
    }

    .md-is-open .menu-icon {
        opacity: 0;
        transform: scale(0);
    }

    .md-is-open .close-icon {
        opacity: 1;
        transform: scale(1);
        background-color: #fff;
        font-size: 24px;
        font-weight: 600;
    }

    a.md-button {
        div.ex-fab-speed-dial-button-no-icon {
            margin-top: 6px;
        }
    }

    button.md-button, a.md-button {
        md-icon {
            color: $grey-secondaire !important;
        }
    }
}

.ex-fab-speed-dial-button-no-icon {
    width: 24px;
    height: 24px;
}

div.ex-fab-speed-dial-button-no-icon {
    border-radius: 50%;
    color: $white;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
