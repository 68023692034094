.dialog-infos-complementaires {
    max-height: 100%;

    .ex-dialog-md-toolbar-tools-titre {
        padding-left: 0px;
    }

    .ex-grid-body {
        border-bottom: 0px;
    }

    .ex-grid-loader {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.dialog-infos-complementaires-multi-edition {
    margin-left: -16px;
    margin-right: -16px;
}
