.dialog-cycle-evenement-historique {
    min-width: 1000px !important;
    max-width: 1000px !important;
    height: 80% !important;
    background-color: $white;

    .ex-dialog-content {
        height: 100%;
    }

    ex-pagination {
        color: $black;
    }
    
    ex-tabs, .ex-tabs-content, .ex-tab-content, ex-multi-occurrence-template {
        flex: 1;
        box-sizing: border-box;
        flex-direction: column;
        display: flex;
    }

    .ex-multi-occurrence-content {
        height: auto !important;
        flex: 1 !important;
    }

    .ex-multi-occurrence-template-row:first-child {
        border-top: 0;
    }

    .ex-multi-occurrence-template-row-accordeon {
        border-top: 1px solid #ddd;
        margin-left: 16px;
        margin-right: 16px;
        padding-bottom: 16px;
    }

    .ex-multi-occurrence-template-body {
        margin-left: 5px;
        margin-right: 5px;
    }

    ex-multi-occurrence-advanced, ex-card-multi-advanced ex-grid.layout-fill {
        margin: 0 !important;
        width: 100% !important;
    }

    ex-card-multi-advanced {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;

        >ex-card {
            margin-bottom: 0 !important;
        }
    }

    .ex-multi-occurrence-template-no-data {
        margin-top: 16px;
    }

    .ex-multi-occurrence-container .ex-multi-occurrence-container {
        display: block;
    }
}
