.dialog-filtre {
    md-dialog-content {
        overflow: hidden;
        padding-top: 26px;
    }
}

.dialog-filtre-description {
    line-height: 10px;
    padding-bottom: 24px !important;
}

.ex-filtre--required {
    .ex-filtre-boutons-radio {
        label:after {
            content: ' *';
            font-size: 13px;
            vertical-align: top;
            color: $red-500 !important;
        }
    }
}
