ex-multi-occurrence-maitre-details-ecran {
    ex-bandeau-page {
        background-color: #FAFAFA;
    }
}

.ex-multi-occurrence-maitre-details-ecran-content {
    overflow-y: auto;
}

.ex-multi-occurrence-maitre-details-ecran-container {
    background-color: #FAFAFA;
}

.ex-multi-occurrence-maitre-details-ecran-haut-content {
    margin-bottom: -16px;
}
