.ex-pastille-filtre-term-content {
    color: $black;
    padding: 10px 10px 3px 10px;
    float: left;
    background: transparent;
    border: 0;
}

.ex-pastille-filtre-remove-btn:focus {
    outline: none;
}

.ex-pastille-filtre-term-content:not(:disabled):hover {
    color: $deep-orange-400;
}

.ex-pastille-filtre-term-content:not(:disabled):focus {
    outline: none;
}

.ex-pastille-filtre-remove-btn {
    height: 44px;
    color: #ccc;
    font-size: 36px;
    padding-right: 6px;
    padding-left: 6px;
    float: inherit;
    background: transparent;
    border: 0;
}

.ex-pastille-filtre[disabled] {
    cursor: default !important;
}

.ex-pastille-filtre {
    background-color: white;
    border-radius: 2px;
    border-left: 4px solid;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);
    cursor: pointer;
    float: left;
    height: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
}

.ex-pastille-filtre:not([disabled]):hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.60);
}

.ex-liste-pastille-filtre-valeur {
    min-height: 24px;
    font-weight: normal;
}

.ex-pastille-filtre--non-modifiable {
    cursor: default !important;

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35) !important;
    }
}
