.dialog-pieces-jointes {
    .ex-dialog-div-content {
        background-color: $white;
    }

    .ex-multi-occurrence-template-row {
        outline: none;
    }

    md-dialog-actions {
        background-color: $white;
    }
}

.dialog-pieces-jointes-multi-occurrence {
    margin: 0 -8px;
}
