ex-data-bar {
    display: block !important;

    .ex-data-value {
        padding-left: 0 !important;
    }

    ex-data-value {
        flex: none !important;

        .ex-data-value-content {
            flex: none !important;
        }
    }
}

.ex-data-bar-value {
    display: flex;
}

.ex-data-bar-ligne-container {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-radius: 2px;
    width: 100%;
    height: 8px;
}

.ex-data-bar-ligne {
    width: 0;
    height: 100%;
    transition:0.4s linear;
}

.ex-data-bar-ratio-positif {
    color: $green-500;
}

.ex-data-bar-ratio-negatif {
    color: $red-500;
}
