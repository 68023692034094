.ex-input-recherche-box-recherche {
    background-color: #ECEFF1;
    border-radius: 15px;
    height: 36px;
    width: 100% !important;

    .md-button.md-icon-button {
        margin: 0 0px;
        width: 37px;
        min-width: 37px;
        height: 36px;
        min-height: 36px;
        padding: 0px;
    }
}

.ex-input-recherche-input-wrapper {
    position: relative;
}

.ex-input-recherche-input {
    width: 100%;
    padding-left: 5px;
    border: 0px;
    height: 34px;
    background-color: transparent;
}

.ex-input-recherche-input:focus {
    outline: none;
}
