.pagination > li:first-child > a, .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 5px 10px;
    margin-top: 2px;
    margin-left: 5px;
    line-height: 1.42857143;
    text-decoration: none;
    background-color: transparent;
}

.pagination > li > a:hover {
    background-color: #f1f1f1;
}

.pagination {
    padding: 0;
    margin: 0;
    border-radius: 4px;
    display: inline-block;
}

.pagination > li {
    display: inline;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    z-index: 3;
    color: #000;
    cursor: default;
    background-color: #CFD8DC; //Blue Grey 100
    border-radius: 7px;
    text-decoration: none;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
    color: #777 !important;
    cursor: default;
    background-color: transparent;
    border-color: #ddd;
    text-decoration: none;
}

ex-pagination .p-t-16 {
    padding-top: 0 !important;
}

ex-pagination {
    display: block;
    @extend .md-caption;
    padding-top: 5px;
    padding-bottom: 5px;
}

ex-pagination md-select {
    margin: 0;
}

ex-pagination .ng-pristine {
    margin: 0;
}

.ex-pagination-navigation {
    font-size: 2.2rem;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    text-decoration: none !important;
    margin: 0 !important;
}

.ex-pagination-navigation-next {
    &:before {
        content: 'chevron_right';
    }
}

.ex-pagination-navigation-prev {
    &:before {
        content: 'chevron_left';
    }
}

.ex-pagination-navigation-first {
    &:before {
        content: 'first_page';
    }
}

.ex-pagination-navigation-last {
    &:before {
        content: 'last_page';
    }
}

.ex-pagination-bouton.md-icon-button {
    margin: 0 4px;
    padding: 0;
    min-height: 24px;
    height: 24px;
    width: 24px;

    &:active, &:focus {
        border-radius: 50%;
    }
}

.ex-pagination-bouton-blend.md-icon-button:not(:disabled):not(:active):not(:hover) md-icon {
    @extend .ex-blue-grey-500;
}
