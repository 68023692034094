ex-image {
    img {
        opacity: 0;
        transition: opacity 1s ease-in;
    }
}

.ex-image-show {
    opacity: 1 !important;
}

.ex-image-hide-busy {
    display: none !important;
}
