ex-multi-occurrence-maitre-details {
    display: block;
    position: relative;
    max-width: 100%;

    .ex-multi-occurrence-content {
        overflow: hidden;
    }

    .ex-multi-occurrence-maitre-details-ajoute-sans-recherche {
        margin-top: 16px;
    }

    .ex-multi-occurrence-maitre-details-ajoute-avec-recherche {
        margin-top: 6px;
    }

    .ex-multi-occurrence-etats {
        padding-top: 8px;
    }

    .ex-multi-occurrence-actions {
        margin-top: -16px;
        margin-bottom: 8px !important;
        padding-left: 16px;
        padding-right: 16px;
    }

    .ex-grid-row {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        display: block;
    }

    .ex-grid-row--opened {
        .ex-multi-occurrence-template-row:not(:hover) {
            &:not(.ex-multi-occurrence-template-row--opened) {
                .ex-grid-menu-actions {
                    visibility: hidden;
                }
            }
        }
    }

    .ex-grid {
        display: block;
        .ex-grid-loader {
            margin-top: 60px;
            margin-bottom: 35px;
        }
    }

    ex-multi-occurrence ex-pagination {
        padding-bottom: 16px;
    }

    .ex-card-content & ex-multi-occurrence .ex-grid .ex-grid-body {
        display: block;
        flex: none;
        overflow: visible;
        max-height: none;
    }

    .ex-accordeon-content {
        padding-bottom: 0 !important;
    }

    .ex-multi-occurrence-content {
        flex: none;
    }

    md-content {
        overflow: visible;
        height: auto;
    }

    ex-erreur-chargement {
        margin-top: 40px;
    }

    .ex-grid-no-data {
        padding-bottom: 32px !important;
        padding-top: 24px !important;
        height: auto;
    }

    ex-multi-occurrence-advanced {
        .ex-grid-no-data {
            padding-bottom: 0 !important;
        }
    }

    .ex-grid-row-totaux {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

.ex-multi-occurrence-maitre-details-template {
    flex-direction: column;

    ex-multi-occurrence-advanced, ex-multi-occurrence-template {
        width: 100%;
        margin-left: 0;
    }

    ex-card-multi-advanced ex-grid.layout-fill {
        width: calc(100% + 2px);
        margin-left: -1px;
    }

    ex-template {
        display: flex;
        flex-direction: column;

        > ex-card-multi-advanced, > ex-card-multi-template {
            margin-left: 16px;
            margin-right: 16px;
        }

        ex-card-multi-advanced, ex-card-multi-template {
            > ex-card {
                margin-bottom: 0;
                margin-top: 0;

                > md-card {
                    margin-bottom: 16px !important;
                }
            }

            &:last-child {
                > ex-card > md-card {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    ex-dashboard-card {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .ex-dashboard-card-container {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .ex-dashboard-card-gauche, .ex-dashboard-card-droite {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

.ex-grid-row {
    &:hover {
        .ex-multi-occurrence-maitre-details--inline-details {
            ex-menu.ex-recherche-menu-actions--menu-more md-icon:not(:hover) {
                @extend .ex-blue-grey-500;
            }
        }
    }

    .ex-multi-occurrence-maitre-details--inline-details {
        @media (min-width: $layout-breakpoint-sm) {
            ex-multi-occurrence-advanced .ex-multi-occurrence-actions-filtres-container {
                margin-left: 0;
                margin-top: 0;
            }

            & > ex-card {
                margin-bottom: 0;
            }
        }

        ex-multi-occurrence-advanced, ex-grid.layout-fill {
            margin: 0 !important;
            width: 100% !important;
        }

        ex-card-multi-advanced {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .ex-grid-row:not(.ex-grid-row--opened):not(.ex-grid-row--active) {
            font-weight: normal;

            .ex-grid-menu-actions {
                visibility: hidden;
            }

            .ex-grid-col {
                font-weight: normal;
            }

            &:hover {
                .ex-grid-menu-actions {
                    visibility: visible;
                }
            }
        }
    }

    .ex-multi-occurrence-maitre-details-loader {
        margin: 16px auto;
    }
}

.ex-multi-occurrence-maitre-details--edition-rapide {
    .ex-multi-occurrence-recherche-container-wrapper {
        display: none;
    }

    ex-multi-occurrence-maitre-details {
        margin-left: 0;
        width: auto;
    }

    ex-grid.layout-fill {
        min-width: 100% !important;
        width: auto !important;
        margin-left: 0 !important;
    }

    md-content {
        overflow-x: auto !important;
        overflow-y: hidden !important;
    }

    .ex-multi-occurrence-actions-filtres-container {
        margin-left: 0 !important;
    }

    ex-pagination {
        margin-right: 7px;
        margin-left: 7px;
        padding-top: 0 !important;
        padding-bottom: 5px !important;
        margin-top: 5px !important;
    }

    .ex-multi-occurrence-pagination-container {
        ex-pagination {
            display: none;
        }
    }

    .ex-formulaire-input-champ {
        flex-direction: column;
    }

    .ex-formulaire-input-champ-boolean {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -1px;

        > div {
            flex: 1;
        }
    }

    .ex-grid-col--action {
        margin-top: 0;
    }

    .ex-formulaire-message-erreur {
        padding-left: 16px;
        padding-top: 4px;
        padding-bottom: 0 !important;

        md-icon {
            max-width: 24px !important;
            box-sizing: content-box !important;
        }
    }

    .ex-formulaire-message-erreur-item:last-child {
        padding-bottom: 0 !important;
    }
}
