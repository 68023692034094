ex-saisie {
    display: block;
}

.ex-saisie-wrapper {
    height: 100%;
    display: block;
}

.ex-saisie-header {
    flex-shrink: 0;
    flex-wrap: wrap;
}

.ex-saisie-titre-container {
    flex: 1 1 200px;
}

.ex-saisie-titre {
    color: #00acc1;
    font-size: 20px !important;
    font-weight: normal;
}

.ex-saisie-header-icon {
    border-radius: 50%;
    padding: 4px;
    box-sizing: content-box;
}

.ex-saisie-content {
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.ex-saisie-actions {
    border-top: 1px solid rgba(0, 0, 0, .12);
    padding: 8px;
    flex-shrink: 0;
}

.ex-saisie-hidden {
    display: none;
}