.ex-ecran-qlik {
    iframe {
        border: none !important;
    }
}

.ex-ecran-qlik-loader {
    margin: auto;
    align-self: center;
}

.ex-ecran-qlik-content {
    margin-bottom: -4px;
    width: 100%;
    height: calc(100% - (40px * 2));
}
