[ex-busy] {
}

.ex-busy-loader {
    float: right;
    margin-left: 10px;
    button > & {
        position: relative;

        margin-top: 10px;
        md-menu-item & {
            margin-top: 0px;
        }
    }

}

md-progress-circular path {
    .md-button.md-primary.md-raised[ex-busy] &, .ex-button-action[ex-busy] &, .ex-button-action [ex-busy] & {
        stroke: #fff;
    }

    .md-button.md-primary.md-raised[ex-busy]:disabled &, .ex-button-action[ex-busy]:disabled & {
        stroke: rgba(0, 0, 0, 0.12);
    }
}
