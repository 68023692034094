ex-multi-occurrence {
    position: relative;

    @media screen and (max-height: 800px) {
        overflow-y: scroll;
    }

    .ex-card-content & {
        display: block;

        ex-grid {
            display: inline-block;
        }

        // Scrolling dans la grid lorsque desktop
        @media (min-width: $layout-breakpoint-sm) {
            .ex-grid .ex-grid-body {
                display: flex;
                overflow-y: scroll;
                flex: 100;
                flex-direction: column;
            }
        }
    }

    // Les cartes imbriquées dans un multi-occurrence non pas de padding
    md-card-content {
        padding: 0;
    }

    md-card {
        margin: 0;
    }

    ex-card {
        margin-top: 8px;

        &.ex-card-criteres-suggeres-visible {
            margin-right: 16px;
            margin-left: 16px;
            margin-bottom: 16px;
            outline: none;
        }

        &.ex-card-criteres-suggeres-invisible {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    ex-saisie-actions {

        .ex-busy-loader {
            float: none;
            margin-left: 0 !important;
            margin-right: 0 !important;
            display: inline;
        }

        [ex-busy] {
            position: relative;
            top: 0;
        }
    }
}

.ex-multi-occurrence-recherche-container-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.ex-multi-occurrence--has-filtres,
.ex-multi-occurrence--has-etats {
    @media (max-width: $layout-breakpoint-sm) {
        .ex-multi-occurrence-recherche {
            .ex-recherche-box-recherche {
                z-index: 8;
            }
        }
    }
}

.ex-multi-occurrence--has-filtres.ex-multi-occurrence--has-etats {
    @media (max-width: $layout-breakpoint-sm) {
        .ex-multi-occurrence-etats {
            padding-bottom: 0;
        }
    }
}

.ex-multi-occurrence-recherche {
    width: auto;
    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 12px;
    padding-bottom: 8px;

    .ex-recherche-box-recherche {
        z-index: 1;
    }

    @media (max-width: $layout-breakpoint-xs) {
        position: static;
        width: auto;
    }
}

.ex-multi-occurrence-actions-filtres-container {
    transition: width linear 0.3s;
}

.ex-multi-occurrence-etats {
    padding-top: 8px;
    padding-left: 14px;
    max-height: 40px !important;

    &.ex-multi-occurrence-etats--padding {
        padding-bottom: 23px !important;
    }

    @media (max-width: $layout-breakpoint-sm) {
        padding-bottom: 16px;
        padding-top: 16px;
        padding-left: 6px;
    }
}

.ex-multi-occurrence-zone-criteres {
    button[ex-expandable-toggle] {
        flex-shrink: 0;
        margin-right: 0 !important;
    }
}

.ex-multi-occurrence--has-filtres:not(.ex-multi-occurrence--has-etats) {
    @media (min-width: $layout-breakpoint-sm) {
        .ex-multi-occurrence-zone-criteres {
            padding-top: 0;
        }
    }
}

.ex-multi-occurrence-texte-criteres-suggeres {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ex-multi-occurrence-filtres-container {
    position: relative;
}

.ex-multi-occurrence-pagination-axe-vision {
    border-top: 1px solid #ddd;
    width: calc(100% + 14px);
    margin-left: -7px;
}

.ex-multi-occurrence-actions-filtres-container {
    flex: 1 1 200px;
}

.ex-multi-occurrence-nb-enregistrement-bas {
    font-size: 12px;
}

.ex-multi-occurrence-pastilles-container {
    padding-top: 4px;
}

.ex-multi-occurrence-bouton-toggle-criteres-suggeres {
    padding-top: 3px;
    max-height: 36px;
    margin-left: auto;
}

.div-criteres-suggeres {
    flex-wrap: wrap;
    height: fit-content;
    flex: 1 1 200px;

    ex-recherche {
        flex: 1;
    }
}