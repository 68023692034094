.ex-input-lov-entite-externe-btn-modal {
    md-icon {
        color: $blue-grey-500;
    }
}
.ex-grid-row--edition-rapide ex-input-lov-entite-externe ex-input-lov + .p-t-8 {
    padding-top: 9px !important;
    .ex-setting-global-densite & {
        padding-top: 1px !important;
    }
}