ex-input-date {
    ng-messages {
        display: flex;
    }

    &[readonly] {
        .md-input {
            @extend .ex-couleur-texte-noir;
            border-bottom: 0 !important;
        }

        md-icon {
            display: none;
        }

        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            left: 0 !important;
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    >md-input-container.ex-input-date {
        >label {
            right: auto !important;
            width: auto !important;
            padding-left: 0 !important;
        }

        >md-datepicker {
            &._md-datepicker-has-triangle-icon {
                padding-right: 0;
                margin-right: 0;
            }

            >button.md-datepicker-button.md-button {
                float: right;
            }

            div.md-datepicker-input-container {
                margin-left: 0;

                button.md-datepicker-triangle-button {
                    display: none;
                }
            }
        }

        >ex-input-validation {
            >div {
                margin-left: 0 !important;
            }
        }
    }
}

.ex-input-date {
    width: 175px;
}

// Prévient le mois "Septembre" d'être sur 2 lignes
.md-calendar-month-label {
    white-space: nowrap;
}
