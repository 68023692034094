ex-menu-employe {
    display: block;

    ex-navigation-menu-content {
        display: block;
        padding-bottom: 16px;
    }

    .ex-navigation-menu-search {
        padding-top: 0 !important;
    }
}
