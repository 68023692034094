ex-button-multi {
    .ex-button-action {
        padding-left: 16px;
    }

    .ex-busy-loader {
        float: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: inline;
    }

    [ex-busy] {
        position: relative;
        top: 0;
        right: 20px;
    }
}

.ex-button-multi-section {
    padding: 0 16px !important;
    font-size: 12px;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.ex-button-multi-option {
    color: black;
    font-size: 14px !important;
    font-weight: normal !important;
    pointer-events: auto;

    md-icon {
        color: rgba(0, 0, 0, 0.54);
        margin-right: 8px !important;
        margin-left: -3px !important;
    }

    .ex-busy-loader {
        margin-top: 8px;
    }
}

.ex-button-multi-option--emphase {
    font-size: 16px !important;
}

.ex-button-multi-option-color-action {
    @extend .ex-couleur-texte-noir
}

.ex-button-multi-divider {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
}

.ex-button-multi-message {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
}

.nbr-select-zone {
    background-color: #FDD19A;
    padding: 10px 18px;
    font-size: 13px;
    font-weight: 500;
    top: -8px;
    position: relative;
}