ex-input-number {
    position: relative;
    ng-messages {
        display: flex;
    }
    //Empêche le bug où deux messages sont affichés au même moment, ce qui
    //cancel l'animation du second message, le rendant invisible.
    ng-message + ng-message {
        display: none;
    }

    input[readonly], input[readonly][disabled] {
        border-bottom: 0;
        color: $black;
    }

    &[readonly], &[readonly][disabled] {
        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    input[readonly] {
        // Prévient l'apparence des boutons plus/moins pour incrémenter le champ
        -moz-appearance: textfield;
    }

    .ex-grid-row--edition-rapide & input {
        text-align: right;
    }
}

.ex-input-number {
    width: 100%;
}

.ex-input-number--increment, .ex-input-number--decrement {
    position: absolute;
    margin: 0 !important;;
    padding: 0 !important;
    top: 2px;
    height: 30px !important;
    min-height: 30px !important;
    width: 30px !important;
}

.ex-input-number--increment {
    right: 0;
}

.ex-input-number--decrement {
    right: 30px;
}

.ex-input-number--align-right {
    text-align: right;
}
