//Variable Globale
$appLargeurPage: 100% !default; // -- Cette variable est utilisée afin de définir la largeur des composants pour une application X
html {
    font-size: 62.5%;
    line-height: 1.4;
    letter-spacing: -0.1px;
}

body {
    font-size: 12px;
}

/*
 * ------------------------------
 * Icônes
 * ------------------------------
 */
// Ces classes sont documentées dans Material Design mais pas présentes dans Angular Material
$icon-sizes: 12 24 36 48;

@each $size in $icon-sizes {
    .material-icons.md-#{$size} {
        font-size: #{$size}px;
        width: 1em;
        height: 1em;
    }

    .md-button.md-icon-button.md-#{$size} {
        width: #{$size + $baseline-grid * 2};
        height: #{$size + $baseline-grid * 2};
        min-height: #{$size + $baseline-grid * 2};
        min-width: #{$size + $baseline-grid * 2};
    }
}

/*
 * ------------------------------
 * Modificateur de typographie
 * ------------------------------
 */
.ex-lowercase {
    text-transform: lowercase !important;
}

.ex-no-text-transform {
    text-transform: initial !important;
}

.ex-uppercase {
    text-transform: uppercase !important;
}

.ex-capitalize {
    text-transform: capitalize !important;
}

.ex-italic {
    font-style: italic;
}

.ex-bold {
    font-weight: 600 !important;

    &.md-button {
        font-weight: 700 !important;
    }
}

.ex-strikethrough {
    text-decoration: line-through;
}
