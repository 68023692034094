ex-card-multi-maitre-details {
    display: block;
    background-color: #FAFAFA;
    overflow-y: visible;

    md-card md-card-content {
        padding-top: 0;
        padding-bottom: 0;
    }

    .ex-criteres-suggeres {
        .ex-saisie-content {
            flex: none;
        }
    }

    .ex-recherche-menu-actions-edition-rapide-fab-button {
        margin-top: -8px;
    }

    .ex-card-multi-maitre-details-content-flex {
        display: flex;
        flex-direction: row;

        >ex-multi-occurrence-maitre-details {
            flex: 1;
        }

        >div:not(:empty) {
            flex: 1;
            margin: 8px;
        }

        .ex-accordeon {
            margin-left: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            ex-accordeon-header {
                padding-left: 8px;
            }
        }
    }
}
