ex-navigation-menu {
    display: inline-block;
    width: calc(100% - 3px) !important;

    >div.layout-fill {
        height: auto;
        max-height: 100%;
    }

    .navigation-menu {
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #B0BEC5;
        margin: 0;
        padding: 0;

        body:not(.mobile) & {
            max-height: calc(100vh - 150px);

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }

            &::-webkit-scrollbar {
                width: 8px;
                background: transparent;
            }

            &:hover::-webkit-scrollbar-thumb {
                background: #bdbdbd;
            }
        }
    }

    .ex-toggle-box-header-bouton-icon {
        padding-top: 0;
        padding-right: 0 !important;
    }

    .ex-toggle-box-header {
        margin: inherit;
        // Taille du scroll
        padding-right: 17px !important;
        box-sizing: border-box;
    }
}
.ex-navigation-menu-size{
    max-width: 500px;
}
.ex-navigation-menu-content {
    display: block;
    min-width: 317px;
    max-width: 500px;
}

.ex-navigation-menu-search {
    min-height: 36px;
    height: 36px;
    padding-top: 16px;
}

.ex-navigation-menu-item--focused {
    background: $grey-200;
    border-left-width: 4px;
    border-left-style: solid;

    .ex-navigation-item-text,
    .ex-navigation-item-text-bold {
        margin-left: -4px;
    }
}