ex-card-cycle {
    md-card-content {
        padding: 6px 16px 12px 16px !important;
    }

    .flex-30 {
        @extend .flex-25;
    }

    .ex-data {
        padding-bottom: 0px !important;
    }

    ex-card-content>div {
        flex-wrap: wrap;

        ex-data {
            min-width: 400px !important;
        }
    }

    ex-data-valeur>button.md-button {
        margin: 0 !important;
        max-width: 100%;
        text-overflow: ellipsis !important;
    }

    .ex-card-cycle-nom-intervenant--button {
        padding-top: 8px;
    }

    ex-card-content>md-divider {
        margin-top: 10px;
    }

    .ex-button-cycle {
        display: inline-block;
    }

    .ex-button-cycle>md-menu,
    .ex-button-cycle>div {

        >button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled),
        >button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled)>md-icon {
            background-color: transparent !important;
            color: #607D8B !important;
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        >button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled):hover,
        >button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled).md-focused {
            background-color: rgba(158, 158, 158, 0.2) !important;
        }
    }

    .ex-card-cycle-buttons-section {
        text-align: center;
        margin-bottom: -12px;

        >span {
            display: inline-block;
        }
    }

    .ex-card-cycle-coche {
        min-width: 100%;

        >ex-coche {
            min-width: 100%;

            md-icon {
                width: max(25%, 100px);
            }
        }

        >ex-coche .ex-coche-texte * {
            color: $black;
            font-size: 14px;
        }
    }

    .ex-card-cycle-coche .floatStaticWidth {
        float: left;
        width: 117px;
    }

    .ex-card-cycle-coche .float {
        float: left;
    }

    .ex-card-cycle-coche .flexVertical {
        display: flex;
        flex-direction: row;
    }

    md-checkbox.marginTopCustom.md-checked .md-ink-ripple,
    .marginTopCustom:not(.md-checked) .md-icon {
        margin-top: 11px;
    }

    md-checkbox.marginTopCustomVerificationFin {
        margin-left: 30px;
    }

    md-checkbox.blockClick {
        pointer-events: none;
    }
}

.ex-card-cycle--piece-justificative {
    float: left;
}