.dialog-selection-intervenant {
    ex-multi-occurrence-template-row {
        flex: 1;
    }

    .ex-dialog-div-content, md-dialog-actions {
        background-color: $white;
    }

    .ex-dialog-div-content {
        overflow: hidden;
        padding-top: 8px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ex-grid-menu-actions {
        margin-top: 0;
    }

    .ex-multi-occurrence-template-loader {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    md-content.ex-multi-occurrence-content.layout-fill {
        height: auto;
    }
}

.dialog-selection-intervenant-loader {
    margin: 16px auto;
}

