.dialog-formulaire {
    min-width: inherit;

    ex-dialog-actions-secondaires {
        button {
            flex-direction: row-reverse !important;
        }

        md-progress-circular {
            float: right;
            width: 16px !important;
            height: 16px !important;
            margin-left: 8px;

            svg {
                width: 16px !important;
                height: 16px !important;
                transform-origin: 8px 8px 8px !important;
            }

            path {
                width: 16px !important;
                height: 16px !important;
                stroke: $white;
            }
        }
    }
}

.dialog-formulaire--initializing {
    .ex-dialog-md-toolbar, md-dialog-actions {
        display: none;
    }
}
