.ex-toolbar {
    max-height: 48px;
    min-height: 48px;
    padding: 0 16px;
    z-index: 70 !important;
}

.ex-toolbar .md-button.md-icon-button:not([disabled]):hover md-icon {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 50%;
}

.ex-toolbar-icon-menu {
    display: inline-block;
    height: 40px;
    width: 40px;
    font-size: 24px;
    line-height: 40px !important;
    text-align: center;
    vertical-align: top;
    @extend .ex-couleur-texte-blanc;
}

.app-toolbar-logo-compagnie {
    max-height: 36px;
    margin-top: 4px;
    max-width: 285px;
}

.ex-toolbar-texte {
    font-size: 20px;
    text-transform: none;
    font-weight: normal;
}

.ex-toolbar-zone-right {
    .md-button.md-icon-button {
        margin: 0px;
        height: inherit;
        width: inherit;
    }
}

.ex-toolbar-outils {
    top: 48px;
    max-width: 400px;}

.ex-toolbar-outils-menu {
    margin: 0;
    padding: 0;
    li {
        min-height: 36px;
    }
}

.ex-toolbar-outils-menu-item {
    .md-button {
        letter-spacing: 0;
        text-transform: none;
        text-align: left;
        margin: 0;
    }

    md-icon {
        margin: 16px 4px 16px 0;
    }
}

.ex-toolbar-outils-menu-item ex-menu-item md-icon {
    margin: 16px 0;
}

.ex-toolbar-outils-titre {
    margin-left: 8px;
    margin-bottom: 8px;
}

.ex-toolbar-aide-technique--active {
    md-icon {
        color: #ea7600 !important;
    }
}

.ex-toolbar-bouton-theme-couleur {
    padding: 16px 8px;

    .ex-toolbar-bouton-theme-couleur-choix {
        margin: 0 4px;
    }
}

.ex-toolbar-bouton-theme-couleur-choix {
    border-radius: 50%;
    width: 36px;
    min-width: 0;
}

.ex-toolbar-tools-action-button {
    >div.ex-menu-item >a.ex-menu-item-button,
    >div.ex-menu-item >button,
    >div.ex-menu-item >button > span.ex-menu-item-titre {
        width: 100%;
        color: $black !important;
        line-height: auto;


        md-icon>span {
            display: inline-block;
            color: $blue-grey-700;
        }
    }
}

.ex-compagnie-nom {
    margin: 0 !important;
}

.ex-toolbar-button-logo-compagnie {
    margin: 0 0 0 16px !important;
    height: 48px;
    display: grid;

    .ex-toolbar-texte {
        vertical-align: middle;
        display: inline-block;
        padding-top: 6px;
        padding-bottom: 6px; 
    }
}

.app-versions {
    padding-top: 24px;
    padding-left: 16px;
}
