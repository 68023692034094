ex-input-lov-bouton-radio {
    ex-checkbox-container {
        min-height: 0 !important;
        padding-bottom: 16px;
    }

    .ex-data-value-container {
        display: block;
    }
}

.ex-input-lov-bouton-radio-radio-container--no-detail {
    display: block;

    md-radio-button {
        display: block;
    }
}

.ex-input-lov-bouton-radio-details {
    margin-left: 30px;
}
