ex-data-value {
    // Vieille propriété
    word-wrap: break-word;
    // Nouvelle propriété
    overflow-wrap: break-word;
}

ex-dashboard-card-content-bottom {
    .ex-data-value-content {
        white-space: pre-wrap;
    }
}

.ex-data-text-bold-black {
    font-weight: bold;
}

.ex-data-value-content {
    margin-top: auto;
    margin-bottom: auto;

    .ex-boolean-data-type {
        white-space: nowrap;
    }

    &.ex-data-value-type-bouton {
        white-space: normal;

        button.md-button.md-icon-button {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.ex-data-value-with-forage-content {
    margin-top: 8px;
}

.ex-data-value-bouton {
    position: relative;
    top: -1px;

}

.ex-data-value-bouton-icon {
    color: $deep-orange-400;
}

.ex-data-value-bouton-icon:hover {
    color: darken($deep-orange-400, 12%) !important;
}

.ex-data-value-bouton-forage {
    margin-top: -3px;

    .md-button {
        width: auto;
        padding: 0;
        height: inherit;
        min-height: initial;

        md-icon {
            margin: 0;
        }
    }
}

.ex-data-menu-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.ex-data-menu-icon-div-parent {
    display: flex;
    position: relative;
}

.ex-data-value-button-icon{
    min-width: 0px;
    width: 30px;
    padding: 0;
    margin: 0;
}