.ex-introspection {
    position: absolute;
    z-index: 100000;
    background-color: $white;
    box-shadow: 0 2px 5px $black-ombre-portee;
    overflow: auto;
    max-height: 250px;
}

.ex-introspection--invisible {
    opacity: 0;
}

.ex-introspection-item {
    padding: 8px 16px;
    font-size: 14px;
    min-height: 22px;
    cursor: pointer;

    &:hover {
        background: $grey-200;
    }
}

.ex-introspection-item--active {
    background: $grey-200;

    &:before {
        content: '';
        left: 0;
        margin-top: -8px;
        width: 2px;
        min-height: 38px;
        position: absolute;
    }
}
