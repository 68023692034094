ex-toggle-box, ex-toggle-box-header, ex-toggle-box-content {
    display: block;
}

.ex-toggle-box-header-bouton {
    text-align: left;
    text-transform: none;
}

.ex-toggle-box-header {
    cursor: pointer;

    position: relative;
    padding: 0px 0px 0px 6px;
    width: 100%;
    box-sizing: content-box;
    min-height: 20px;
    margin: 0px 0px 0px -6px;

    .ex-toggle-box-header-bouton-icon {
        padding-right: 10px;
    }
}
