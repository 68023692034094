ex-input-hour {
    &[readonly] {
        .md-input {
            @extend .ex-couleur-texte-noir;
            border-bottom: 0 !important;
        }

        md-icon {
            display: none;
        }

        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    >mdp-date-picker>div {
        >md-input-container {
            >label {
                right: auto !important;
                width: auto !important;
            }

            >md-datepicker {
                &._md-datepicker-has-triangle-icon {
                    padding-right: 0;
                    margin-right: 0;
                }

                >button.md-datepicker-button.md-button {
                    float: right;
                }

                div.md-datepicker-input-container {
                    margin-left: 0;

                    button.md-datepicker-triangle-button {
                        display: none;
                    }
                }
            }

            >ex-input-validation {
                >div {
                    margin-left: 0 !important;
                }
            }
        }
    }
}

mdp-time-picker .md-button.md-icon-button {
    margin: 10px 0 0 0;
    flex-shrink: 0;
}

.mdp-clock-container > .md-button.md-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%;
}

.ex-input-hour--required label:after {
    content: ' *';
    font-size: 13px;
    vertical-align: top;
    color: $red-500 !important;
}
