// Fixes pour les backdrops de dialogues multiples
md-backdrop.md-dialog-backdrop ~ md-backdrop.md-dialog-backdrop {
    z-index: 81;
}

md-backdrop.md-dialog-backdrop ~ md-backdrop.md-dialog-backdrop ~ md-backdrop.md-dialog-backdrop {
    z-index: 83;
}

.md-dialog-container ~ .md-dialog-container {
    z-index: 82;
}

.md-dialog-container ~ .md-dialog-container ~ .md-dialog-container {
    z-index: 84;
}
