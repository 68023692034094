ex-card {
    display: block;

    >md-card {
        margin-bottom: 24px;
    }

    &[wrap-content] {
        ex-card-content {
            flex-wrap: wrap;
            flex-direction: row;
            display: flex;
            box-sizing: border-box;
            flex: 1;
        }
    }

    &[ex-fullscreen]> {
        .fullscreen-actif {
            .ex-card-content {
                padding-left: 36px;
                padding-right: 36px;

            }
        }

        md-card {
            height: 100%;

            md-card-title {
                flex-shrink: 0;
                flex-grow: 0;
            }

            .ex-card-content-container {
                display: flex;
                flex-direction: column;
                overflow: auto;
                background: white;
                flex-grow: 1;

                &>* {
                    flex-shrink: 0;
                }
            }

            .ex-multi-occurrence-content {
                display: block !important;
                height: auto !important;
                min-height: 300px;
            }

            ex-multi-occurrence>div {
                display: block;
            }
        }
    }
}

.ex-card-collapsable {
    md-card-title {
        height: 40px;
        cursor: pointer;
    }
}

.ex-card-erreur {
    .ex-card-title-text {
        color: $red-500 !important;

        md-icon {
            color: $red-500 !important;
        }
    }
}

.ex-card-avertissement {
    .ex-card-title-text {
        color: $orange-500 !important;

        md-icon {
            color: $orange-500 !important;
        }
    }
}

.ex-card-color {
    background-color: #FFFFFF;
}

.ex-card-actions {
    margin: 0;

    button md-icon,
    md-icon {
        color: $blue-grey-500;
    }

    ex-menu .md-button {
        margin-right: 0 !important;
    }

    .md-button:not([disabled]).md-focused {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }

    .md-icon-button {
        margin-left: 0 !important;
    }
}

.ex-card-title {
    z-index: 2;
    font-size: 18px;
    font-weight: normal;
}

.ex-card-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    display: inline-block;
    line-height: 40px;
}

.ex-card-content {
    .ex-card-content-forage {
        visibility: hidden;
    }

    &:hover {
        .ex-card-content-forage {
            visibility: visible;
        }
    }
}

.ex-card-content-forage {
    .md-button {
        margin: -8px 0;
    }
}

.ex-card-content-sans-padding-top {
    padding-top: 0;
}

.ex-card-content-full-right:empty {
    display: none;
}

.ex-card-content-full-bottom:empty {
    display: none;
}

.ex-card-header-divider {
    position: relative;
    z-index: 1;
}

.ex-card-transclude-titre {
    display: inline-block;
}

.ex-card-ex-case-group-parent {
    display: flex;
    width: 100%;
}

.ex-card-ex-case-group-child {
    display: flex;
    flex-direction: row;
}

.ex-card-hist {
    display:flex; 
    justify-content: space-between;
}
