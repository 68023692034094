ex-mono-occurrence {
    position: relative;
    display: block;

    .ex-multi-occurrence-advanced-details-template {
        flex-direction: column;

        ex-multi-occurrence-advanced, ex-multi-occurrence-template {
            width: 100%;
            margin-left: 0;
        }

        ex-card-multi-advanced ex-grid.layout-fill {
            width: calc(100% + 2px);
            margin-left: -1px;
        }

        ex-template {
            display: flex;
            flex-direction: column;

            > ex-card-multi-advanced, > ex-card-multi-template {
                margin-left: 16px;
                margin-right: 16px;
            }

            ex-card-multi-advanced, ex-card-multi-template {
                > ex-card {
                    margin-bottom: 0;
                    margin-top: 0;

                    > md-card {
                        margin-bottom: 16px !important;
                    }
                }

                &:last-child {
                    > ex-card > md-card {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        ex-dashboard-card {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .ex-dashboard-card-container {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 8px !important;
            margin-right: 8px !important;
        }

        .ex-dashboard-card-gauche, .ex-dashboard-card-droite {
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding-left: 8px !important;
            padding-right: 8px !important;
        }

        .ex-grid-loader {
            min-height: 74px;
        }
    }

    md-card, .ex-regroupement {
        margin: 2px 0 14px 0 !important;
    }

    ex-card-multi-maitre-details {
        overflow-x: visible;
    }

    ex-tab {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
    }
}

.ex-mono-occurrence-ecran-titre-icon {
    display: inline-block;
    margin-right: 8px;

    md-icon {
        color: $white;
    }
}

ex-mono-occurrence-ecran-gauche {
    .ex-dashboard-card-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ex-dashboard-card-gauche {
        margin-left: 0 !important;
    }

    .ex-dashboard-card-droite {
        margin-right: 0 !important;
    }
}

.ex-mono-occurrence--marge {
    margin-right: 16px;
}

ex-mono-occurrence-ecran {

    ex-mono-occurrence-ecran-haut, ex-mono-occurrence-ecran-bas {
        > ex-card-multi-advanced,
        > ex-card-multi-template,
        > ex-card-multi-maitre-details,
        > ex-card-multi-hierarchie,
        > ex-card,
        > ex-card-saisie,
        > ex-regroupement,
        > ex-mono-occurrence,
        > ex-card-infos-monetaires {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    ex-panneau-page {

        md-content.p-16 {
            flex: 1;
            height: 100%;
            box-sizing: border-box;
            padding: 0 !important;
        }
    }

    ex-panneau-page md-content, ex-panneau-page-content {
        flex-direction: column;
        display: flex;
        flex: 1;
        height: 100%;
    }
}

.ex-mono-occurrence-ecran-content {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #FAFAFA;

    &:before {
        content: ' ';
        display: block;
        height: 8px;
        background-color: #FAFAFA;
    }
}

.ex-mono-occurrence-ecran-container-bouton-action-wrapper {
    z-index: 8;
    padding-top: 8px;
    background-color: #FAFAFA;
}

.ex-mono-occurrence-ecran-container-bouton-action {
    min-height: 30px;
    height: 30px;

    .md-button {
        line-height: 36px !important;
        min-height: 36px !important;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.ex-mono-occurrence-ecran-bouton-no-action:hover:not(:disabled):not(.ex-disabled--disabled),
.ex-mono-occurrence-ecran-bouton-no-action:focus:not(:disabled):not(.ex-disabled--disabled) {
    background-color: #90a4ae !important;
}

.ex-mono-occurrence-ecran-formulaire-loader {
    min-height: 200px;
}

.ex-mono-occurrence-ecran-loader {
    margin: 140px auto 0 auto;
}

.ex-mono-occurrence-ecran--creation {
    // On cache les multi enfants durant la création
    ex-card-multi-template,
    ex-card-multi-hierarchie,
    ex-card-multi-advanced,
    ex-card-multi-maitre-details,
    .ex-regroupement:not(.ex-regroupement-toujours-visible) {
        display: none;
    }

    ex-pieces-jointes > .ex-pieces-jointes-ajout-rapide {
        display: block;
    }
}

.ex-mono-occurrence-ecran--bloc-creation {
    ex-card-multi-template,
    ex-card-multi-advanced {
        display: block;
    }
}

.ex-mono-occurrence-ecran-content-wrapper {
    width: 100%;
    margin-right: auto;
}

.ex-mono-occurrence-ecran-main-content-wrapper {
    min-height: calc(100vh - (48px * 4) - 6px);
}

.ex-mono-occurrence-ecran-main-content-wrapper-sans-boutons-actions {
    min-height: calc(100vh - (48px * 3) - 16px);
}

.ex-mono-occurrence-ecran-fixed-width {
    .ex-dashboard-card-container, md-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ex-dashboard-card-gauche {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    ex-card-formulaire-maitre {
        md-card {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.ex-mono-occurrence-ecran-boutons-cycle--autres-actions {
    .ex-menu-item-titre {
        flex: 1;
    }
}

.ex-occurrence-bandeau-resume {
    font-size: 12px;
}

// Dans le cas où l'on est directement dans la page (pas dans une modale)
.app-container .ex-occurrence-bandeau-resume {
    color: $black;
}

.ex-occurrence-titre {
    flex-wrap: wrap;
}