button.md-button.ex-favoris {
    border-radius: 50%;

    &:hover {
        .ex-favoris-actif:before {
            content: attr(ex-icon-inactif);
            color: $blue-grey-500 !important;
        }

        .ex-favoris-inactif:before {
            content: attr(ex-icon-actif);
        }
    }

    .ex-favoris-inactif:before {
        content: attr(ex-icon-inactif);
        color: $blue-grey-500 !important;
    }

    .ex-favoris-actif:before {
        content: attr(ex-icon-actif);
    }
}
