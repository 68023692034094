// Librairies
// Configuration Angular Material SCSS
$button-icon-border-radius: 0;
$appLargeurPage: 100%;

/*@import "../../core/styles/fonts/material-icons";*/
@import "../../core/styles/fonts/roboto";
@import "../../core/styles/fonts/font-awesome/font-awesome";

@import "~angular-material/angular-material";
@import "~angular-dragula/dist/dragula";
@import "~@uquebec/md-pickers/dist/mdPickers";

// Core
@import "../../core/styles/colors";
@import "../../core/styles/layout";
@import "../../core/styles/toasts";
@import "../../core/styles/typographie";
@import "../../core/styles/inputs";
@import "../../core/styles/buttons";

// Core Layouts
@import "../../core/layouts/vertical-navigation/vertical-navigation";

// Core Components
@import "../../core/components/ex-accordeon/ex-accordeon";
@import "../../core/components/ex-axe-affichage/ex-axe-affichage";
@import "../../core/components/ex-bandeau-page/ex-bandeau-page";
@import "../../core/components/ex-bouton-compteur-pieces-jointes/ex-bouton-compteur-pieces-jointes";
@import "../../core/components/ex-bouton-nouveau/ex-bouton-nouveau";
@import "../../core/components/ex-button-tri/ex-button-tri";
@import "../../core/components/ex-bouton-imputation/ex-bouton-imputation";
@import "../../core/components/ex-button-multi/ex-button-multi";
@import "../../core/components/ex-calendrier/ex-calendrier";
@import "../../core/components/ex-card-multi-advanced/ex-card-multi-advanced";
@import "../../core/components/ex-card/ex-card";
@import "../../core/components/ex-card-cycle/ex-card-cycle";
@import "../../core/components/ex-case-edition-assistee/ex-case-edition-assistee";
@import "../../core/components/ex-card-formulaire-maitre/ex-card-formulaire-maitre";
@import "../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires";
@import "../../core/components/ex-card-message/ex-card-message";
@import "../../core/components/ex-card-multi-template/ex-card-multi-template";
@import "../../core/components/ex-card-multi-hierarchie/ex-card-multi-hierarchie";
@import "../../core/components/ex-card-saisie/ex-card-saisie";
@import "../../core/components/ex-case-description/ex-case-description";
@import "../../core/components/ex-case/ex-case";
@import "../../core/components/ex-case-bouton/ex-case-bouton";
@import "../../core/components/ex-checkbox-container/ex-checkbox-container";
@import "../../core/components/ex-coche/ex-coche";
@import "../../core/components/ex-criteres-suggeres/ex-criteres-suggeres";
@import "../../core/components/ex-cycle-list-etapes/ex-cycle-list-etapes";
@import "../../core/components/ex-data/ex-data";
@import "../../core/components/ex-data-bar/ex-data-bar";
@import "../../core/components/ex-data-list/ex-data-list";
@import "../../core/components/ex-data-value/ex-data-value";
@import "../../core/components/ex-data-value-list/ex-data-value-list";
@import "../../core/components/ex-dialog/ex-dialog";
@import "../../core/components/ex-dashboard-card/ex-dashboard-card";
@import "../../core/components/ex-edition/ex-edition";
@import "../../core/components/ex-erreur-chargement/ex-erreur-chargement";
@import "../../core/components/ex-erreur-messages/ex-erreur-messages";
@import "../../core/components/ex-etats/ex-etats";
@import "../../core/components/ex-fab-speed-dial-circle/ex-fab-speed-dial-circle";
@import "../../core/components/ex-favoris/ex-favoris";
@import "../../core/components/ex-filtre/ex-filtre";
@import "../../core/components/ex-formulaire-input/ex-formulaire-input";
@import "../../core/components/ex-formulaire-input-list/ex-formulaire-input-list";
@import "../../core/components/ex-formulaire/ex-formulaire";
@import "../../core/components/ex-grid/ex-grid";
@import "../../core/components/ex-hierarchie-item/ex-hierarchie-item";
@import "../../core/components/ex-infos-complementaires/ex-infos-complementaires";
@import "../../core/components/ex-input-date/ex-input-date";
@import "../../core/components/ex-input-date-hour/ex-input-date-hour";
@import "../../core/components/ex-input-description/ex-input-description";
@import "../../core/components/ex-input-file-upload/ex-input-file-upload";
@import "../../core/components/ex-input-hour/ex-input-hour";
@import "../../core/components/ex-input-imputation/ex-input-imputation";
@import "../../core/components/ex-input-intervalle/ex-input-intervalle";
@import "../../core/components/ex-input-lov/ex-input-lov";
@import "../../core/components/ex-input-lov-bouton-radio/ex-input-lov-bouton-radio";
@import "../../core/components/ex-input-lov-entite-externe/ex-input-lov-entite-externe";
@import "../../core/components/ex-input-number/ex-input-number";
@import "../../core/components/ex-input-parametre-multiple/ex-input-parametre-multiple";
@import "../../core/components/ex-input-recherche/ex-input-recherche";
@import "../../core/components/ex-input-string/ex-input-string";
@import "../../core/components/ex-image/ex-image";
@import "../../core/components/ex-liste-pastille-filtre/ex-liste-pastille-filtre";
@import "../../core/components/ex-multi-occurrence/ex-multi-occurrence";
@import "../../core/components/ex-multi-occurrence-template/ex-multi-occurrence-template";
@import "../../core/components/ex-multi-occurrence-advanced/ex-multi-occurrence-advanced";
@import "../../core/components/ex-multi-occurrence-hierarchie/ex-multi-occurrence-hierarchie";
@import "../../core/components/ex-menu/ex-menu";
@import "../../core/components/ex-menu-item/ex-menu-item";
@import "../../core/components/ex-menu-selection-specialisee/ex-menu-selection-specialisee";
@import "../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran";
@import "../../core/components/ex-mono-occurrence/ex-mono-occurrence";
@import "../../core/components/ex-navigation-item/ex-navigation-item";
@import "../../core/components/ex-navigation-menu/ex-navigation-menu";
@import "../../core/components/ex-pagination/ex-pagination";
@import "../../core/components/ex-pastille-filtre/ex-pastille-filtre";
@import "../../core/components/ex-pieces-jointes/ex-pieces-jointes";
@import "../../core/components/ex-recherche/ex-recherche";
@import "../../core/components/ex-recherche-menu-actions/ex-recherche-menu-actions";
@import "../../core/components/ex-regroupement/ex-regroupement";
@import "../../core/components/ex-saisie/ex-saisie";
@import "../../core/components/ex-select-colonne/ex-select-colonne";
@import "../../core/components/ex-selecteur/ex-selecteur";
@import "../../core/components/ex-selection-employe/ex-selection-employe";
@import "../../core/components/ex-selection-multiple-advanced/ex-selection-multiple-advanced";
@import "../../core/components/ex-sommaire/ex-sommaire";
@import "../../core/components/ex-sommaire-sections/ex-sommaire-sections";
@import "../../core/components/ex-splash-screen/ex-splash-screen";
@import "../../core/components/ex-suivi-modification/ex-suivi-modification";
@import "../../core/components/ex-toggle-box/ex-toggle-box";
@import "../../core/components/ex-toggle-icon/ex-toggle-icon";
@import "../../core/components/ex-titre/ex-titre";
@import "../../core/components/ex-tabs/ex-tabs";
@import "../../core/components/ex-input-validation/ex-input-validation";
@import "../../core/components/navigation/navigation";

// Core Dialog
@import "../../core/dialogs/dialog-info/dialog-info";
@import "../../core/dialogs/dialogs";
@import "../../core/dialogs/dialog-alert/dialog-alert";
@import "../../core/dialogs/dialog-confirm/dialog-confirm";
@import "../../core/dialogs/dialog-cycle-evenement-informations/dialog-cycle-evenement-informations";
@import "../../core/dialogs/dialog-cycle-evenement-historique/dialog-cycle-evenement-historique";
@import "../../core/dialogs/dialog-cycle-transactionnel-informations/dialog-cycle-transactionnel-informations";
@import "../../core/dialogs/dialog-cycle-transactionnel-historique/dialog-cycle-transactionnel-historique";
@import "../../core/dialogs/dialog-ecran/dialog-ecran";
@import "../../core/dialogs/dialog-edition-avancee/dialog-edition-avancee";
@import "../../core/dialogs/dialog-filtre/dialog-filtre";
@import "../../core/dialogs/dialog-formulaire/dialog-formulaire";
@import "../../core/dialogs/dialog-imputation/dialog-imputation";
@import "../../core/dialogs/dialog-lov-entite-externe/dialog-lov-entite-externe";
@import "../../core/dialogs/dialog-suivi-modification/dialog-suivi-modification";
@import "../../core/dialogs/dialog-etats/dialog-etats-ajout";
@import "../../core/dialogs/dialog-pieces-jointes/dialog-pieces-jointes";
@import "../../core/dialogs/dialog-requerant/dialog-requerant";
@import "../../core/dialogs/dialog-selection-intervenant/dialog-selection-intervenant";
@import "../../core/dialogs/dialog-selection-intervention/dialog-selection-intervention";
@import "../../core/dialogs/dialog-selection-employe/dialog-selection-employe";
@import "../../core/dialogs/dialog-compteur-lignes/dialog-compteur-lignes";
@import "../../core/dialogs/dialog-champ-selection-multiple/dialog-champ-selection-multiple";
@import "../../core/dialogs/dialog-tooltip/dialog-tooltip";
// Core Behaviors
@import "../../core/behaviors/ex-busy/ex-busy";
@import "../../core/behaviors/ex-disabled/ex-disabled";
@import "../../core/behaviors/ex-expandable/ex-expandable";
@import "../../core/behaviors/ex-introspection/ex-introspection";
@import "../../core/behaviors/ex-sticky/ex-sticky";

// App
@import "app";

// Views
@import "pages/accueil/accueil";
@import "pages/cp/cp0066/cp0066";
@import "pages/cp/cp2000/cp2000";
@import "pages/cp/cp2002/cp2002";
@import "pages/ch/ch0001/ch0001";

// Top level components
@import "toolbar/toolbar";

// App components
@import "./components/ex-button-historique/ex-button-historique";
@import "./components/ex-button-historique-colonne/ex-button-historique-colonne";
@import "./components/ex-button-fab-cycle/ex-button-fab-cycle";
@import "./components/ex-button-fab-selection/ex-button-fab-selection";
@import "./components/ex-card-infos-financieres/ex-card-infos-financieres";
@import "./components/ex-card-multi-maitre-details/ex-card-multi-maitre-details";
@import "./components/ex-ecran-qlik/ex-ecran-qlik";
@import "./components/ex-fonctions-transversales/ex-fonctions-transversales";
@import "./components/ex-infos-financieres/ex-infos-financieres";
@import "./components/ex-menu-employe/ex-menu-employe";
@import "./components/ex-multi-occurrence-analyse/ex-multi-occurrence-analyse";
@import "./components/ex-multi-occurrence-maitre-details/ex-multi-occurrence-maitre-details";
@import "./components/ex-multi-occurrence-maitre-details-ecran/ex-multi-occurrence-maitre-details-ecran";
@import "./components/ex-notes/ex-notes";
@import "./components/ex-notes-finance/ex-notes-finance";
@import "./components/ex-panneau-page/ex-panneau-page";
@import "./components/ex-plaquettes-fonctions-transversales/ex-plaquettes-fonctions-transversales";
@import "./components/ex-input-lov-requerant/ex-input-requerant";

// App Dialog
@import "./dialogs/dialog-donnees-enregistrement/dialog-donnees-enregistrement";
@import "./dialogs/dialog-historique-colonne/dialog-historique-colonne";
@import "./dialogs/dialog-infos-complementaires/dialog-infos-complementaires";
@import "./dialogs/dialog-multi-occurrence/dialog-multi-occurrence";
@import "./dialogs/dialog-recuperation/dialog-recuperation";
@import "./dialogs/dialog-planification-employe/dialog-planification-employe";
