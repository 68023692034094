.ex-suivi-modification-circle {
    position: relative;
    border: 2px solid;
    border-radius: 100%;
    float: left;
    width: 33px;
    height: 33px;
    line-height: 30px;
    text-align: center;
    margin-top: 25px;
    clear: left;
    z-index: 2;
}

.ex-suivi-modification-divider {
    margin: 0px 7px 0px 45px;
    border-top-width: 2px;
}

.ex-suivi-modification-circle:first-child {
    margin-top: 0;
}

.ex-suivi-modification-circle:before {
    position: absolute;
    border: 2px solid;
    width: 0;
    height: 58px;
    display: block;
    content: '';
    left: 16px;
    z-index: 1;
    top: -60px;
    margin-left: -1px;
}

.ex-suivi-modification-circle-avec-mod:after {
    position: absolute;
    border: 2px solid;
    width: 0;
    height: 80px;
    display: block;
    content: '';
    left: 16px;
    top: 20px;
    margin-left: -1px;
    z-index: -1;
}

.ex-suivi-modification-circle:first-child:before {
    display: none;
}

.ex-suivi-modification-libelle-usager {
    display: block;
}

.ex-suivi-modification-nom-usager {
    display: block;
    text-align: right;
}
