.ex-dialog-selection-intervention {
    width: 650px;
    min-width: 400px;
}

.ex-dialog-selection-intervention-erreur {
    padding-bottom: 16px;
    color: $red-500;

    md-icon {
        color: $red-500;
    }
}
