ex-input-imputation {
    width: 100%;

    &[readonly=readonly] {
        label {
            display: contents;
        }

        .ex-input-imputation.md-input-has-value>label {
            display: block;
        }
    }
}

.ex-input-imputation {
    width: 100%;

    input {
        text-overflow: ellipsis;
        width: calc(100% - 50px);
    }

    .md-icon-button {
        margin-right: 0;

        &:focus {
            border-radius: 50% !important;
        }

        md-icon {
            @extend .ex-blue-grey-500;
        }
    }
}

.ex-input-imputation--loading {
    margin-left: 50%;
    position: absolute;
}

.ex-input-imputation-count-disabled {
    .md-char-counter {
        display: none;
    }
}

.ex-input-imputation-affichage-consultation {
    letter-spacing: initial;
    color: rgba(0, 0, 0, 0.87);
}
