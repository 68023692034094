ex-card-infos-monetaires {
    display: block;

    .ex-fab-icon md-icon {
        margin-left: 7px;
        margin-top: 5px;
    }

    md-card-content {
        padding: 6px 16px !important;
    }

    .ex-data {
        padding-bottom: 0px !important;
    }
}

.ex-card-infos-monetaires-value {
    text-align: right;
}

.ex-card-infos-monetaires-invalid-value {
    color: $red-500;
}

.ex-card-infos-monetaires-col-icone {
    margin-top: 4px;
}

.ex-setting-global-densite .ex-card-infos-monetaires-col-icone {
    margin-top: -4px;
}

.ex-card-infos-monetaires-content {
    display: block;
}

.ex-card-infos-monetaires-row-important {
    ex-data .ex-bold, ex-data .md-body-1 {
        font-weight: bold !important;
    }
}

.ex-card-infos-monetaires-divider-no-icons {
    margin: 0 -16px;
}

.ex-card-infos-monetaires--alignement-donnees {
    padding-right: 52px;
}
