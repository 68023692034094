ex-data-list {
    display: block;
}

.ex-data-list {
    flex-wrap: wrap;
}

.ex-data-list-ligne {
    flex-basis: 200px;
}

.ex-data-list-value-right {
    .ex-data-value {
        text-align: right;
    }
}

.ex-data-list-menu-spacer {
    min-width: 40px;
}
