.ex-fonctions-transversales-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px !important;

    md-icon {
        color: #fff;
    }
}

.ex-fonctions-transversales-titre {
    font-size: 20px;
    height: 56px;
    line-height: 24px;
    width: 100%;
}

.ex-fonctions-transversales--notes {
    overflow: auto;
}

.ex-fonctions-transversales-back {
    &.md-button {
        height: 56px;
        width: 46px;
    }

    md-icon {
        font-size: 36px;
        height: 36px;
        width: 36px;
    }
}

.ex-fonctions-transversales--infos-financieres {
    padding: 16px 24px 0 24px;
    overflow-y: auto;
}

.ex-fonctions-transversales-piece {
    color: #00BCD4;

    .md-button {
        margin-right: 0;
    }
}

.ex-fonctions-transversales-piece-libelle {
    height: 40px;
}

.ex-fonctions-transversales-content {
    display: block;
}

.ex-fonctions-transversales--multi-occurrence {
    .ex-card-multi-advanced-agrandir {
        display: none;
    }

    .ex-grid-body>div {
        overflow: auto;
        max-height: calc(100vh - 300px);
    }

    .ex-recherche-menu-actions-edition-rapide {
        display: none;
    }
}