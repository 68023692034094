ex-multi-occurrence-template {
    display: block;


    .ex-multi-occurrence-content {
        flex: none;
        overflow: hidden;

        .ex-saisie-content {
            overflow: hidden;
        }
    }

    ex-pieces-jointes {
        ex-card {
            margin-bottom: 0;
        }
    }

    ex-erreur-chargement {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .ex-multi-occurrence-template-row.ex-multi-occurrence-template-row--opened {
        outline: none;
        .ex-multi-occurrence-template-row:not(.ex-multi-occurrence-template-row--opened):not(.ex-multi-occurrence-template--menu-visible) {
            .ex-grid-menu-actions {
                visibility: hidden !important;
            }

            &:hover {
                .ex-grid-menu-actions {
                    visibility: visible !important;
                }
            }
        }
    }

    .md-icon-button:not(.ex-pagination-bouton) {
        min-height: 32px;
    }

    .ex-grid-menu-actions {
        margin-top: 0;
        .md-button {
            height: 40px;
        }
    }

}

.ex-multi-occurrence-template-row--total {
    background-color: $blue-grey-50;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.ex-multi-occurrence-template-row--selected, .ex-multi-occurrence-template-row--active {
    font-weight: 600;
    background-color: $blue-grey-50;
    outline: none;

    .ex-grid-menu-actions {
        visibility: visible;
    }

    .ex-multi-occurrence-template-row-end-of-line {
        font-weight: 600;
        width: 100%
    }
}

ex-multi-occurrence-template-row,
ex-card-multi-template-row {
    padding-top: 8px;
    padding-bottom: 8px;
    outline: none;
    width: 100%;
}

.ex-multi-occurrence-template-no-data {
    margin-bottom: 24px;
}

.ex-multi-occurrence-template-row {
    border-top: 1px solid #ddd;
    outline: none;

    &.gu-mirror {
        border: 1px solid #ddd;
    }

    &:not(.ex-multi-occurrence-template-row--opened):hover {
        background-color: $blue-grey-50;
    }

    &:not(.ex-multi-occurrence-template-row--opened):hover,
    &.ex-multi-occurrence-template-row--opened,
    &.ex-multi-occurrence-template--menu-visible {
        .ex-grid-menu-actions {
            visibility: visible !important;
        }
    }
}

.ex-multi-occurrence-template-row--last {
    border-bottom: 1px solid #ddd;
}

.ex-multi-occurrence-template-loader {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
}

.ex-multi-occurrence-template-row--has-accordeon {
    position: relative;

    ex-multi-occurrence-template-row,
    ex-card-multi-template-row,
    .ex-multi-occurrence-template-row-end-of-line-container {
        cursor: pointer;
    }
}

.ex-multi-occurrence-template-row--opened {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    ex-multi-occurrence-template-row,
    ex-card-multi-template-row {
        position: relative;
        z-index: 10;
        font-weight: bold;
    }

    ex-multi-occurrence-template {
        ex-multi-occurrence-template-row,
        ex-card-multi-template-row {
            font-weight: normal;
        }
    }

    .ex-multi-occurrence-template-row-accordeon {
        position: relative;
        border-top: 1px solid #ddd;
        z-index: 10;
    }

    .ex-grid-menu-actions {
        visibility: visible;
    }

    .ex-multi-occurrence-template-row--closed {
        .ex-grid-menu-actions {
            visibility: hidden;
        }
    }
}

.ex-multi-occurrence-template-loader--datalist-visible {
    margin-top: 130px;
    margin-left: 50%;
    position: absolute;
}

.ex-multi-occurrence-template-loader {
    margin: 16px auto;
}

.ex-multi-occurrence-template-row--active {
    font-weight: 600;
    background-color: $blue-grey-50;
}

.ex-multi-occurrence-template-row--activable {
    ex-multi-occurrence-template-row,
    ex-card-multi-template-row {
        cursor: pointer;
    }
}

.ex-multi-occurrence-template-checkbox-selection {
    margin-bottom: 0;

    .md-container {
        width: 18px !important;
        height: 18px !important;
    }

    .md-icon {
        border-color: #0097A7;
        width: 18px !important;
        height: 18px !important;

        &:after {
            left: 4px !important;
            top: -1px !important;
        }
    }
}

.ex-multi-occurrence-template-bris-container {
    padding-left: 4px;
    background-color: $blue-grey-50;
    color: $black;
    font-weight: bold;

    &:empty {
        display: none;
    }

    md-icon {
        color: $black;
    }
}

.ex-multi-occurrence-template-row--forage-on-click {
    a {
        text-decoration: none !important;
    }

    &:not(:hover) {
        ex-case:not(.ex-case-coche):not(.ex-rouge-500) {
            text-decoration-color: black;
            .ex-case:not(.ex-couleur-texte-second).ex-case-content, a {
                color: black;
            }
        }
    }

    &:hover {
        ex-case:not(.ex-case-coche) {
            text-decoration-color: $blue-500;
            .ex-case:not(.ex-couleur-texte-second).ex-case-content, a {
                color: $blue-500;
                text-decoration: underline;
            }
        }
    }
}

.ex-multi-occurrence-template-row--total-menus {
    width: 52px;
}

.ex-multi-occurrence-template--collapsed-bris {
    .ex-multi-occurrence-template-row,
    .ex-multi-occurrence-template-row--last {
        box-shadow: none;
        border: none;
    }
}
