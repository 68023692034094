.cp2002 {
    .cp2002-preview {
        height: 100%;
        min-height: 75vh;
        display: block;

        embed {
            width: 100%;
            height: 100%;
            min-height: 500px;
        }
    
        img {
            display: block;
            max-width: 100%;
            margin: auto;
        }
    }

    .cp2002-icon-visible {
        visibility: visible;
    }
}
