ex-input-lov-requerant {
    display: block;
    position: relative;

    .ex-input-lov-description {
        padding-right: 26px;
    }
}

.ex-input-requerant-button {
    position: absolute;
    right: 0;
    margin: -4px !important;
    top: 8px;
}
