ex-card-formulaire-maitre {
    md-card {
        background-color: $white;
    }
}

.ex-card-formulaire-maitre-actions {
    margin-top: -10px;

    ex-menu .md-icon-button {
        padding-right: 8px !important;
        margin-right: 12px !important;
    }
}

.card-formulaire-maitre-no-header > md-card {
    padding-top: 8px;
}