ex-notes-finance {
    display: block;

    .ex-multi-occurrence-recherche-container {
        padding-left: 16px;
        padding-right: 16px;
    }

    .ex-multi-occurrence-content {
        overflow: visible;
        flex: none;
    }

    .ex-saisie-content {
        overflow: hidden;
    }

    @media (min-width: ($layout-breakpoint-xs)) {
        .ex-input-date {
            float: right;
        }
    }

    .ex-multi-occurrence-template-container {
        display: block;

        .ex-multi-occurrence-container {
            display: block;
        }
    }
}

.ex-notes-finance-description {
    white-space: pre-line;
    width: 100%;
}

.ex-notes-finance-grid-row {
    font-size: 14px;
    outline: 0;

    .ex-notes-finance-grid-row-champ-secondaire {
        font-size: 12px;
        @extend .ex-couleur-texte-second;

        md-icon {
            @extend .ex-couleur-texte-second;
        }
    }
}

.ex-notes-finance--has-pieces-jointes {
    width: 24px;
}
