ex-dialog {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    min-width: 800px;

    md-dialog {
        opacity: 1;
        max-width: initial;
    }

    md-toolbar {
        min-height: 50px;
        height: 50px;

        ex-recherche-menu-actions {

            button.md-button.md-icon-button,
            button.md-button.md-icon-button.ex-button-icon-action.ex-bouton-nouveau {
                &:not([disabled]):not(:hover) md-icon {
                    color: #fff !important;
                }

                &[disabled] md-icon {
                    color: $grey-secondaire;
                }
            }
        }
    }

    md-dialog-actions {
        border-top: 1px solid rgba(0, 0, 0, .12);
    }

    .md-icon-button.md-button:not([disabled]).md-focused,
    ex-input-string .md-button:not([disabled]).md-focused {
        border-radius: 50% !important;
    }
}

.ex-dialog-div-content {
    @extend .p-h-8;
}

.ex-dialog-actions-secondaires {
    .md-button {
        @extend .ex-button-secondary;
    }
}

.ex-dialog-actions {
    .md-button {
        @extend .ex-button;
        @extend .ex-button-action;
        display: inline-block;

        span {
            text-align: center;
            min-width: 40px;
        }
    }
}

.ex-dialog-actions,
.ex-dialog-actions-secondaires {
    .md-button {
        @extend .md-raised;
        min-width: 50px !important;
    }
}

.dialog-filtre--largeur-criteres-suggeres {
    min-width: 700px !important;
}

.ex-dialog-md-toolbar {
    max-height: 45px;
    min-height: 45px;
}

.ex-dialog-md-toolbar-tools-close {
    &:hover {
        md-icon {
            color: $deep-orange-400;
        }
    }
}

.ex-dialog-md-toolbar-tools-titre {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #FFFFFF;
}

@media screen and (max-width: $layout-breakpoint-xs) {

    ex-dialog {
        min-width: inherit;
    }
}

.md-toolbar-tools {
    button {
        color: white !important;
    }

    md-icon {
        color: white !important;
    }
}