.ex-disabled--disabled, .ex-disabled--disabled:hover {
    &:not(.ex-button-action-plat) {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    &.md-icon-button {
        background-color: transparent !important;
    }

    &.ex-button-action-plat {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.38) !important;

        md-icon {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    span {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.38) !important;
    }

    cursor: default;
    box-shadow: none !important;
}
