.ex-sticky-placeholder--sticked {
    position: fixed;
    z-index: 75;
    box-shadow: 0 0 4px rgba(0, 0, 0, .10), 0 4px 6px rgba(0, 0, 0, .10);
}

.ex-sticky-placeholder {
    display: none;
    opacity: 0;
}
