ex-input-description {
    ng-messages {
        display: flex;
    }
    //Empêche le bug où deux messages sont affichés au même moment, ce qui
    //cancel l'animation du second message, le rendant invisible.
    ng-message + ng-message {
        display: none;
    }

    .ex-input-description textarea.md-input {
        min-height: 30px;
    }

    textarea[readonly], textarea[readonly][disabled] {
        position: relative;
        top: -2px;
        border-bottom: 0;
        @extend .ex-couleur-texte-noir;
    }

    &[readonly] {
        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    md-icon {
        @extend .ex-blue-grey-500;
    }

    .ex-input-description-print {
        display: none;
    }
}

.ex-input-description {
    width: 100%;
    .md-button[disabled] md-icon {
        color: rgba(0, 0, 0, 0.38) !important;
    }
}

.ex-input-description-edition-avancee-button {
    position: absolute;
    right: -4px;
    bottom: 24px;
}

.ex-input-description--edition-avancee {
    textarea {
        // Pour ne pas que l'icône soit couvert par le texte
        padding-right: 40px;
    }
}

.ex-input-string-count-disabled {
    .md-char-counter {
        display: none;
    }
}
