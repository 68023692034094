ex-coche {
    md-icon {
        position: relative;
        top: -2px;
        font-size: 26px;
    }
}

.ex-coche-texte {
    margin-left: 2px;
}
