.ex-axe-affichage-template {
    flex-direction: column;

    ex-multi-occurrence-advanced, ex-multi-occurrence-template {
        width: 100%;
        margin-left: 0;
    }

    ex-card-multi-advanced ex-grid.layout-fill {
        width: calc(100% + 2px);
        margin-left: -1px;
    }

    ex-template {
        display: flex;
        flex-direction: column;

        > ex-card-multi-advanced, > ex-card-multi-template {
            margin-left: 16px;
            margin-right: 16px;
        }

        ex-card-multi-advanced, ex-card-multi-template {
            > ex-card {
                margin-bottom: 0;
                margin-top: 0;

                > md-card {
                    margin-bottom: 16px !important;
                }
            }

            &:last-child {
                > ex-card > md-card {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    ex-dashboard-card {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .ex-dashboard-card-container {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .ex-dashboard-card-gauche, .ex-dashboard-card-droite {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .ex-grid-loader {
        min-height: 74px;
    }
}
