.ex-selection-multiple-advanced-multi-occurrence {
    display: block;
    overflow: hidden;

    .ex-grid-body {
        overflow: hidden !important;
    }

    .ex-grid-loader {
        margin-top: 32px;
    }

    ex-grid.layout-fill {
        min-width: 100%;
        overflow: hidden;
        width: auto !important;
    }

    md-content.ex-multi-occurrence-content {
        overflow-y: hidden;
        overflow-x: auto !important;

        body:not(.mobile) & {
            &::-webkit-scrollbar {
                height: 8px;
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #bdbdbd;
            }
        }
    }
}