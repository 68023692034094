ex-regroupement {
    display: block;

    ex-accordeon-content {
        padding-left: 16px;
        padding-right: 16px;
        display: block;
    }

    .ex-dashboard-card-container.m-h-16 {

        &, .ex-dashboard-card-gauche.m-h-8 {
            margin-left: 0!important;
        }

        &, .ex-dashboard-card-droite.m-h-8 {
            margin-right: 0!important;
        }
    }

    ex-dashboard-card-content-bottom {
        > ex-card-multi-advanced, > ex-card-multi-template, > ex-card-saisie {
            margin-left: -8px;
            margin-right: -8px;
        }
    }
}

.ex-regroupement {
    background-color: $grey-200;

    ex-card-multi-maitre-details {
        background-color: transparent;
        padding: 0 8px;
        margin: 0 -8px;
    }

    &.highlighted {
        .ex-regroupement-accordeon > div.ex-accordeon-container > div.md-subhead.ex-accordeon {
            background-color: transparent !important;
        }
    }
}

.ex-regroupement-accordeon {
    display: inline;
    height: 40px;

    & > div.ex-accordeon-container {
        min-height: 40px;
    }

    & > div.ex-accordeon-container > div.md-subhead.ex-accordeon {
        padding-top: 8px !important;
        padding-left: 16px;
        padding-right: 8px;
        background-color: $grey-200 !important;
        color: $blue-grey-600 !important;

        button:not(:disabled) md-icon:not(:hover):not(:disabled), ex-accordeon-actions button:not(:disabled) md-icon:not(:hover):not(:disabled) {
            color: $blue-grey-600 !important;
            background-color: transparent !important;
        }

        button:disabled md-icon {
            color: rgba(0, 0, 0, 0.12) !important;
            background-color: transparent !important;
        }
    }
}
