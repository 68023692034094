ex-cycle-list-etapes {
    flex: 1;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
}

.ex-cycle-list-etapes-gauche {
    height: 100%;
    padding-left: 6px;
}

.ex-cycle-list-etapes-droite {
    padding-top: 3px;
}

.ex-cycle-list-etapes-indicateur {
    background-color: $black;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    line-height: 28px;
    text-align: center;
    z-index: 1;

    &:not(.ex-primary-bg) {
        opacity: 0.5;
    }

    md-icon {
        font-size: 20px;
    }
}

.ex-cycle-list-etapes-indicateur--actif {
    md-icon {
        color: #fff
    }
}

.ex-cycle-list-etapes-description {
    font-size: 14px;
    color: $black;
}

.ex-cycle-list-etapes-description-evidence {
    font-size: 16px;
}

.ex-cycle-list-etapes-lien {
    background-color: $black;
    opacity: 0.25;
    width: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 25px;
}

.ex-cycle-list-etapes-info-secondaire {
    font-size: 12px;
}
