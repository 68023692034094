ex-card-saisie {
    display: block;
}

.ex-card-saisie-loader {
    min-height: 200px;
}

.ex-card-saisie-formulaire {
    .ex-card-content {
        padding-bottom: 0 !important;
    }
}

.ex-card-saisie-formulaire-checkbox {
    .ex-card-content {
        padding-bottom: 0 !important;
        .ex-setting-global-densite & {
            padding-bottom: 16px !important;
        }
    }
}