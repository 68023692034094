ex-sommaire-sections {
    display: block;

    [ex-expandable-toggle] {
        margin-right: 11px !important;
    }
}

.ex-sommaire-sections-item {
    display: block;
    text-decoration: none;
    padding: 2px 4px 2px 16px;
    color: $black !important;
    font-weight: 400;
    outline: 0;

    &:hover {
        background: $grey-200;
    }

    ex-sommaire-sections ex-sommaire-sections & {
        padding-left: 12 * 2 + 4px;

        ex-sommaire-sections & {
            padding-left: 12 * 3 + 4px;;

            ex-sommaire-sections & {
                padding-left: 12 * 4 + 4px;

                ex-sommaire-sections & {
                    padding-left: 12 * 5 + 4px;

                    ex-sommaire-sections & {
                        padding-left: 12 * 6 + 4px;
                    }
                }
            }
        }
    }
}

ex-sommaire-sections ex-sommaire-sections {
    margin-bottom: 2px;

    .ex-sommaire-sections-champ {
        color: $grey-medium !important;
    }
}
