ex-input-date-hour {
    .ex-input-message-animation {
        margin-top: -24px;
        margin-left: 21px;
        color: $red-500 !important;
    }

    &[readonly] {
        .md-input {
            @extend .ex-couleur-texte-noir;
            border-bottom: 0 !important;
        }

        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    md-input-container.ex-input-date label {
        max-width: calc(100% - 118px) !important;
        right: 50px !important;
    }

    .md-datepicker-input-container, ex-input-date {
        display: flex;
        flex-direction: row;
    }

    .md-datepicker-input-container {
        flex: 1;
    }

    .md-datepicker-input {
        min-width: 100% !important;
        padding-right: 20px;
    }

    ex-input-date {
        padding-left: 12px;
    }

    .ex-input-date {
        width: 100%;
        max-width: 220px !important;
    }
}

.ex-input-date-hour-container {
    position: relative;
    vertical-align: middle;
}

.ex-input-date-hour-container.ng-invalid-validate-api {
    md-input-container label, md-input-container .md-input-message-animation, md-input-container .md-char-counter {
        color: $red-500 !important;
    }
    md-input-container .md-input {
        border-color: $red-500 !important;
    }
}
