.ex-dialog-ecran-dialog {
    max-width: 1200px;

    &:not(.ex-dialog-ecran--has-actions) {
        md-dialog-actions {
            display: none !important;
        }
    }
}

.ex-dialog-ecran {
    width: calc(100vw - 32px) !important;
    max-width: 100% !important;
    height: calc(100vh - 32px) !important;

    .ex-dialog-content, .ex-dialog-div-content {
        display: flex;
        flex: 1;
        padding: 0 !important;
        max-height: 100%;
        overflow-x: hidden;
    }

    .ex-dialog-container {
        min-height: 0;
    }

    .ex-dialog-md-toolbar {
        .ex-dialog-md-toolbar--hide & {
            display: none !important;
        }
    }
    .ex-dialog-md-toolbar {
        .ex-dialog-ecran--initialized & {
            display: flex;
        }
    }

    ex-mono-occurrence-ecran, ex-multi-occurrence-analyse, ex-multi-occurrence-maitre-details-ecran {
        flex: 1;
        max-height: 100%;
    }

    .ex-multi-occurrence-analyse-container {
        background-color: $white;
    }

    .ex-bandeau-page-retour {
        display: none;
    }

    ex-bandeau-page {
        overflow: hidden;
        margin-bottom: 2px;
        display: contents;

        .ex-occurrence-titre-code {
            color: $white;
        }

        ex-bandeau-page-titre {
            color: $white;
        }

        button:not([disabled]).md-button.md-icon-button, a:not([disabled]).md-button.md-icon-button {
           md-icon:not([disabled]):not(:hover):not(.ex-bandeau-page-panneau-button-icon) {
                color: inherit;
            }
        }

        .md-button:not([disabled]):hover {
            background-color: transparent;
        }

        .ex-recherche-menu-actions-dans-entete md-icon,
        ex-bouton-nouveau .md-button:not([disabled]):not(.ex-disabled--disabled) md-icon {
            color: $white;
        }
    }

    .ex-bandeau-page:not(.ex-bandeau-page--has-panneau-page) {
        padding-right: 0 !important;
    }

    ex-bandeau-page-titre {
        font-size: 20px;
    }

    .ex-bandeau-page-fermer-dialog {
        display: block;

        md-icon {
            color: $white;
        }
    }

    .ex-multi-occurrence-container {
        background: $white;
    }

    .ex-mono-occurrence-ecran-container-bouton-action {
        box-shadow: none;
        height: 38px;
        min-height: 38px;
        margin-bottom: 5px;
    }

    .ex-mono-occurrence-ecran-content-wrapper.ex-mono-occurrence-ecran-main-content-wrapper {
        min-height: calc(100% - 72px) !important;
    }

    .ex-bandeau-page-titre-container {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.ex-dialog-ecran--initialized:not(.ex-dialog-ecran--no-action) {
    md-dialog-actions {
        display: flex !important;
    }
}

.ex-dialog-ecran-loader {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    color: $white;

    path {
        stroke: #fff;
    }
}

.ex-dialog-ecran-erreur-chargement {
    width: 100%;
    margin-top: 120px;
}

