ex-button-tri {
    width: 100%;

    .md-button {
        display: flex;
        flex-direction: row;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        text-transform: none;
        letter-spacing: inherit;

        &::-moz-focus-inner {
            padding: 0;
            border: 0
        }

        &:hover {
            background: transparent !important;
        }
    }
}

.ex-button-tri--required {
    &:after {
        content: '\00a0*';
        font-size: 13px;
        vertical-align: top;
        color: #F44336 !important;
    }
}

.ex-button-tri--inactive {
    cursor: default !important;
}

.ex-bouton-tri-bloc {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    line-height: 24px;
    margin-top: auto;
    margin-bottom: auto;
}

.ex-button-tri-icon {
    transition: transform 0.3s ease-in-out;
    margin: 0 0 0 5px;
}

.ex-button-tri-icon--asc {
    transform: rotate(-180deg);
}
