.ex-card-infos-financieres-content {
    display: inline-block;
}

.ex-card-infos-financieres-button {
    color: #607D8B !important;

    md-icon {
        color: #607D8B !important;
    }
}
