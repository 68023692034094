.dialog-multi-occurrence {
    overflow: hidden;
    min-width: 600px;

    .ex-dialog-content {
        background-color: $white;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
    }

    ex-multi-occurrence {
        display: block !important;
        padding-top: 8px;
        margin: 0 -8px;
    }

    ex-card-multi-advanced {
        padding-bottom: 48px !important;
    }

    ex-card-multi-advanced ex-multi-occurrence {
        margin: 0 !important;
    }

    md-dialog-actions {
        background-color: $white;
    }

    .ex-multi-occurrence-recherche-container {
        padding: 0 8px;
    }

    .ex-multi-occurrence-zone-criteres {
        padding-top: 8px !important;
    }

    .ex-grid-col-container {
        min-width: 100%;
    }
}

.dialog-multi-occurrence-loader {
    height: 200px;
}
