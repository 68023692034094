.dialog-suivi-modification {
    min-width: 400px;
    .ex-dialog-md-toolbar-tools-titre {
        padding-left: 0px;
    }
    .md-transition-in {
        transition: inherit;
        transform: inherit;
    }
}

