ex-input-parametre-multiple {
    position: relative;
}

.ex-input-parametre-multiple {
    min-width: 100%;
    input {
        visibility: hidden;
    }
}

.ex-input-parametre-multiple-saisie {
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    color: $black;
}

.ex-input-parametre-multiple-menu-item {
    float: right;
    margin-top: -32px;
    margin-right: -16px;
}
