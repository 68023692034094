$yellow-uq: #fbb900 !important;

$black: rgba(0, 0, 0, 0.87);
$black-ombre-portee: rgba(0, 0, 0, .25);
$black-ombre-portee-pale: rgba(0, 0, 0, .15);
$black-ombre-portee-tres-pale: rgba(0, 0, 0, .05);
$black-transparent: rgba(0, 0, 0, 0);

$indigo-a100: #8C9EFF;
$indigo-500: #3F51B5;

$blue-500: #2196F3;
$blue-400: #42A5F5;

$blue-grey-50: #ECEFF1;
$blue-grey-200: #B0BEC5;
$blue-grey-400: #78909C;
$blue-grey-500: #607D8B;
$blue-grey-600: #546E7A;
$blue-grey-700: #455A64;

$amber-900: #FF6F00;

$deep-orange-400: #FF7043;
$deep-orange-500: #FF5722;

$orange-500: #FF9800;
$orange-400: #FFA726;

$amber-700: #FFA000;
$amber-400: #FFCA28;

$yellow-a200: #ffff00;

$green-500: #4caf50;
$green-600: #43A047;

$teal-500: #009688;

$light-green-a400: #CCFF90;

$grey-200: rgb(238, 238, 238);
$grey-medium: rgba(0, 0, 0, 0.54);
$grey-pale: rgba(0, 0, 0, 0.12);
$grey-secondaire: rgba(0, 0, 0, 0.38);

$red-50: #ffebee;
$red-500: #F44336;
$red-a700: #D50000;

$pink-400: #EC407A;

$brown-200: #BCAAA4;
$brown-100: #D7CCC8;

$white-normalized: rgba(255, 255, 255, 0.87) !important;
$white: rgb(255, 255, 255) !important;
$white-medium: rgba(255, 255, 255, 0.54);
$background-color-fixe: rgba(0, 0, 0, 0.03);

.ex-blue-grey-50 {
    color: $blue-grey-50;
}

.ex-blue-grey-50-bg {
    background-color: $blue-grey-50 !important;
    @extend .ex-couleur-texte-noir
}

.ex-blue-grey-200 {
    color: $blue-grey-200;
}

.ex-blue-grey-400 {
    color: $blue-grey-400;
}

.ex-grey-200-bg {
    background-color: $grey-200;
    color: $white-normalized;
}

.ex-blue-grey-400-bg {
    background-color: $blue-grey-400;
    color: $white-normalized;
}

.ex-blue-grey-500 {
    color: $blue-grey-500 !important;
}

.ex-blue-grey-500-bc {
    border-color: $blue-grey-500 !important;
}

.ex-blue-grey-600-bg {
    background-color: $blue-grey-600;
    color: $white-normalized;
}

.ex-blue-grey-700 {
    color: $blue-grey-700;
}

.ex-blue-grey-700-bg {
    background-color: $blue-grey-700;
    color: $white-normalized;
}

.ex-blue-500 {
    color: $blue-500;
}

.ex-blue-500-bg {
    background-color: $blue-500 !important;
    color: $white-normalized;
}

.ex-grey-200-bg {
    background-color: $grey-200 !important;
}

.ex-amber-900-bg {
    background-color: $amber-900 !important;
    color: $white-normalized;
}

.ex-amber-400-bg {
    background-color: $amber-400 !important;
}

.ex-indigo-500-bg {
    background-color: $indigo-500 !important;
    color: $white-normalized;
}

.ex-rouge-500-bg {
    background-color: $red-500 !important;
    color: #fff !important;
}

.ex-teal-500-bg {
    background-color: $teal-500 !important;
    color: $white-normalized;
}

.ex-green-600-bg {
    background-color: $green-600 !important;
    color: $white-normalized;
}

.ex-pink-400-bg {
    background-color: $pink-400 !important;
    color: $white-normalized;
}

.ex-rouge-500 {
    color: $red-500 !important;
}

.ex-rouge-500-divbas>div {
    color: $red-500 !important;
}

.ex-couleur-texte-blanc {
    color: $white-normalized;
}

.ex-couleur-texte-noir {
    color: $black;
}

.ex-couleur-inherit-bg {
    color: inherit !important;
}

.ex-couleur-icone-verte {
    color: $green-500 !important;
}

.ex-couleur-icone-rouge {
    color: $red-500 !important;
}

.ex-emphase-jaune {
    box-shadow: inset 0px 0px 4px 2px $yellow-a200 !important;
}

.ex-emphase-rouge {
    box-shadow: inset 0px 0px 4px 2px $red-a700 !important;
}

.ex-emphase-bleu {
    box-shadow: inset 0px 0px 4px 2px $blue-400 !important;
}

.ex-emphase-vert {
    box-shadow: inset 0px 0px 4px 2px $light-green-a400 !important;
}

.ex-emphase-orange {
    box-shadow: inset 0px 0px 4px 2px $orange-400 !important;
}

.ex-emphase-brun {
    box-shadow: inset 0px 0px 4px 2px $brown-100 !important;
}

.ex-emphase-indigo {
    box-shadow: inset 0px 0px 4px 2px $indigo-a100 !important;
}

.ex-couleur-texte-second {
    color: rgba(0, 0, 0, 0.54) !important;
}

.ex-ligne-inactive {
    color: rgba(127, 81, 28, 0.87) !important; /* #7F511C avec une opacité de 87% */
    font-style: italic;
}

.ex-avertissement-bg {
    background-color: $orange-500 !important;
}

.ex-couleur-noir {
    color: #000000;
}

.ex-erreur {
    color: $red-500;
}

.ex-avertissement {
    color: $orange-500;
}

.ex-ligne-erreur {
    color: $red-500;
}

.ex-ligne-cedule {
    color: $blue-400;
}

.ex-ligne-en-attente {
    color: $orange-500;
}

.ex-ligne-en-cours {
    color: $green-500;
}

.ex-colonne-total {
    background-color: $background-color-fixe;
    font-weight: 700;
}