ex-case {
    display: block;

    ex-data-value {
        flex: 1;
    }

}

.ex-case-content {
    padding-left: 16px;
    padding-right: 16px;
    line-height: 24px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    min-height: 24px;

    ex-data-value {
        min-width: 100%;
    }

    div {
        display: inline;
    }

    ex-menu-item {
        margin-top: -8px;
        margin-bottom: -8px;
        margin-left: -8px;
        display: inline-block;
    }

    .ex-case-icone {
        margin-right: 4px;
    }
}

.ex-case-icone .ex-case-content {
    padding-right: 0 !important;
}

.ex-case-avec-icone .ex-case-content {
    padding-left: 4px !important;
}

.ex-case--secondaire {
    font-size: 12px;

    md-icon {
        @extend .ex-couleur-texte-second;
    }
}

.ex-case--clear {
    clear: both;
}
