.ex-formulaire-input-list-ligne {
    flex-wrap: wrap;
}

.ex-formulaire-input-list-champ-container {
    flex: 1 0 200px;
}

.ex-formulaire-input-list-saut-de-ligne-end {
    width: 100%;
}

.ex-formulaire-input-list-sous-groupe:not(.ng-hide)+.ex-formulaire-input-list-sous-groupe {
    margin-left: 0;
}

.ex-formulaire-input-list--input-libelle-contour {
    margin-top: 18px;

    &.ex-formulaire-input-list--input-libelle-contour-gauche {
        margin-right: 4px;
    }

    &.ex-formulaire-input-list--input-libelle-contour-droite {
        margin-left: 4px;
    }
}