.ex-checkbox-container {
    position: relative;
    height: 100%;

    label {
        transform: translate3d(0, 4px, 0) scale(0.90);
        -webkit-font-smoothing: antialiased;
        transform-origin: left top;
        color: rgba(0, 0, 0, 0.54);
        display: block;
        margin-bottom: 5px;
        margin-top: -7px;
    }
}

ex-checkbox-container {
    height: 100%;
    min-height: 94px;

    ex-formulaire-input-list & {
        min-height: 0 !important;
    }

    &[readonly] {
        label:after {
            display: none;
        }
    }

    .ex-formulaire-input-radio-bouton {
        padding-right: 8px;
    }
}

.ex-checkbox-focused {
    color: #00ACC1 !important;
}