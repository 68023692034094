.ex-case-bouton {
    .md-button {
        font-size: 12px !important;
        font-weight: 400 !important;;
        .ex-disabled--disabled {
            background-color: inherit !important;
        }

        &[disabled] md-icon:hover {
            color: rgba(0, 0, 0, 0.38);
        }
    }
}

.ex-case-bouton-actions {
    opacity: 0;
}

.ex-case-bouton-actions:hover {
    opacity: 1;
}

.ex-case-bouton {
    margin: -16px;
    padding: 16px;
}
