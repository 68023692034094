ex-data-value-list {
    display: block;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    ex-data-value, .ex-data-value-content, .ex-data-value-container {
        display: inline;
        flex: inherit;
    }
}

.ex-data-value-list-element {
    float: left;
}
