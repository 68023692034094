ex-recherche {
    display: block;

    .md-button:not([disabled]).md-focused {
        border-radius: 50% !important;
    }
}

.ex-recherche-box-recherche {
    background-color: #ECEFF1;
    border-radius: 15px;
    height: 37px;
    width: 100% !important;

    .md-button.md-icon-button {
        margin: 0 0px;
        width: 37px;
        height: 37px;
        padding: 0px;
    }
}

.ex-recherche-input {
    position: relative;
    width: 100%;
    padding-left: 5px;
    border: 0px;
    height: 35px;
    background-color: transparent;
    box-sizing: border-box;
    text-overflow: ellipsis;
}

.ex-recherche-input-sans-parametre {
    margin-right: 10px;
}

.ex-recherche-input:focus {
    outline: none;
}
