.dialog-alert {
    min-width: inherit;
    .ex-dialog-md-toolbar-tools-titre {
        padding-left: 0;
    }
    .md-transition-in {
        transition: inherit;
        transform: inherit;
    }
}

.dialog-alert-message {
    white-space: pre-wrap;
}
