ex-bandeau-page {
    z-index: 9;

    button:not([disabled]):not(.ex-bouton-nouveau).md-button.md-icon-button, a:not(.ex-bouton-nouveau):not([disabled]).md-button.md-icon-button {
        border-radius: 50%;
        &:not([disabled]):not(:hover):focus {
            background-color: rgba(0, 0, 0, 0.12);
        }

        md-icon:not([disabled]):not(:hover) {
            color: $blue-grey-500;
            background-color: transparent !important;
        }
    }

    a.md-button {
        background-color: transparent !important;
    }

    ex-bandeau-page-titre md-icon {
        position: relative;
        top: -1px;
    }

    ex-bouton-nouveau {
        color: transparent !important;
    }
}

ex-bandeau-page-titre, span.ex-bandeau-page-titre {
    color: $blue-grey-500 !important;
}

.ex-bandeau-page-fermer-dialog {
    display: none;
}

.ex-bandeau-page {
    z-index: 9;
    background-size: cover;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 6px rgba(0, 0, 0, 0.14);
}

.ex-bandeau-page-wrapper {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;

    .ex-bandeau-page--fixed-width & {
        margin-left: calc(((100vw - #{$appLargeurPage}) / 2));
    }
}

.ex-bandeau-page-menu {
    padding: 0 2px;

    a {
        font-size: 20px;
        font-weight: 400;
        text-decoration: none;
        text-transform: none;
    }

    a:hover:not([disabled]) {
        text-decoration: underline;
    }

    .md-button:not([disabled]):hover {
        background-color: $grey-200;
    }
}

.ex-bandeau-page-titre {
    font-size: 18px;
    white-space: nowrap;
    box-sizing: border-box;
}

.ex-bandeau-page-titre-container {
    flex: 1 0 auto;
}

.ex-bandeau-page-num-intervention {
    color: $black;
    font-size: 12px;
    margin-left: 8px;
    padding-top: 6px;
}

.ex-bandeau-page-retour {
    margin: 0 0px !important;
    margin-right: 22px !important;

    &[disabled] {
        opacity: .38;
    }
}

.ex-bandeau-page-panneau-button {
    margin-right: -28px !important;
    height: 34px !important;
    min-height: 34px !important;
    width: 48px !important;
    padding: 6px !important;
    background: $white;
    text-align: left !important;
    border-top-left-radius: 36px !important;
    border-bottom-left-radius: 36px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    position: relative;
    z-index: 10;
    align-items: start;
    transition: transform ease-in-out .1s;

    &:not(.ex-bandeau-page-panneau-button--opened) {
        &:hover {
            transform: translateX(-3px);
        }
    }

    &.ex-bandeau-page-panneau-button--opened {
        &:hover {
            transform: translateX(3px);
        }
    }
}

.ex-bandeau-page-panneau-button-icon {
    margin-left: 3px;
    transition: transform ease-in-out .35s;

    .ex-bandeau-page-panneau-button--opened & {
        transform: rotateY(180deg);
    }
}

.ex-bandeau-page-titre-menu {
    flex: 1 0 auto;
}