.ex-dialog-tooltip {
    min-width: 300px;
}

.ex-dialog-tooltip-content {
    text-align: justify;
    word-spacing: 1px;
    letter-spacing: 1px;
    hyphens: auto;
    widows: 2;
    orphans: 2;

    display: inline-block;
    padding: 15px 15px;
    background-color: #f9f9f9;
    max-width: 450px;
    white-space: normal;
    word-wrap: break-word;
}