.ex-formulaire-formulaire-input-list {
    width: 100% !important;
}

.ex-formulaire-pieces-jointes-container {
    min-width: 500px;
    display: block;

    ex-card {
        padding-right: 8px;
        padding-left: 8px;
    }
}
