ex-card-message {
    display: block;

    ex-card {
        margin-left: 8px;
        margin-right: 8px;

        .ex-card-content {
            padding: 0;
        }

        .ex-card-message-header {
            padding: 16px;
            border-top: 1px solid #ddd;
            font-weight: 600;
        }

        .ex-card-message-liste {
            padding: 16px;
            border-top: 1px solid #ddd;
            line-height: 2;
        }

        md-checkbox {
            margin: 0;
        }
    }

    md-icon {
        margin-bottom: 5px;
    }

    .ex-toggle-icon {
        color: $blue-grey-500 !important;
    }
}

.ex-card-message-erreur {
    .ex-card-title-text, .ex-card-title-text md-icon,
    md-card-title-text span, md-card-title-text md-icon {
        color: $red-500 !important;
    }

    md-divider {
        display: none;
    }
}

.ex-card-message-avertissement {
    .ex-card-title-text, .ex-card-title-text md-icon {
        color: #FF9800 !important;
    }
}

.ex-card-message--bouton-action {
    border-top: 1px solid #ddd;
}
