ex-erreur-messages {
    display: block;
    color: $red-500;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 20px;

    .erreur-texte {
        white-space: pre-line;
        line-height: 24px;
    }

    md-icon {
        color: $red-500 !important;
    }
}
