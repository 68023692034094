ex-bouton-compteur-pieces-jointes {
    width: 36px;
    height: 36px;
    float: right;
    margin-top: -10px;
}

.ex-bouton-compteur-pieces-jointes {
    display: block;
    max-height:36px;

    .ex-menu-item {
        display: inline-flex;
    }
}
