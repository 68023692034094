.ex-grid {
    width: 100%;

    .ex-formulaire-form,
    .ex-formulaire-custom-content {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        width: 100%;
    }

    &.ex-grid--edition-rapide {
        ex-button-tri {
            .md-button {
                padding-right: 0;
                padding-left: 14px;

                .ex-setting-global-densite & {
                    padding-right: 14px;
                    padding-left: 19px;
                }
            }
        }

        .ex-setting-global-densite & {
            md-input-container {
                margin: 0 !important;

                &.md-input-invalid .md-input-message-animation {
                    padding-top: 2px;
                    padding-right: 2px;
                }
            }
        }
    }

    .ex-grid-total-print {
        display: none;
    }
}

.ex-grid--loading {
    min-height: 132px;
}

.ex-grid:not(.ex-grid--distribute-width) {

    .ex-grid-col-container,
    .ex-grid-head-col {
        flex-grow: 0 !important;
    }
}

.ex-grid--distribute-width {

    .ex-grid-head-col-wrapper,
    .ex-grid-col-wrapper,
    ex-data-value {
        max-width: 100%;
    }

    .ex-grid-head-col:not(.ex-grid-head-col--action):not(.ex-grid-col-fixe),
    .ex-grid-col:not(.ex-grid-col--action):not(.ex-grid-col-fixe) {
        flex: 1;
    }
}

.ex-grid-bris-expandable-toggle {
    padding-left: 16px;
    padding-top: 8px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;

    .md-icon-button {
        margin: 0;
    }
}

.ex-grid-col-bouton,
.ex-grid-col-bouton-espace {
    min-width: 30px !important;
    flex-grow: 0;
}

.ex-grid-col-bouton-espace {
    min-width: 41px !important;
}

.ex-grid-expandable-target {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ex-grid-expandable-toggle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ex-grid-head,
.ex-grid-head-espace {
    border-bottom: 2px solid rgba($blue-grey-500, .5);
    box-shadow: 0 6px 3px -2px rgba(0, 0, 0, 0.1) !important;
    position: relative;
    z-index: 1;
}

.ex-grid-col-bouton-deplacement-fixe {
    min-width: 30px;
    padding-bottom: 5px;
}

.ex-grid-head-espace {
    // Grandeur de la scrollbar
    padding-right: 17px !important;
}

.ex-grid-body {
    border-bottom: 1px solid #ddd;
}

.ex-grid-head-row--entete-titre {
    padding: 0 !important;
}

.ex-grid-head-row {
    min-height: 57px;
}

.ex-grid-head-row,
.ex-grid-row {
    outline-width: 0;

    &:first-child {
        cursor: default;
    }
}

.ex-grid-head-col--action,
.ex-grid-col--action {
    width: 52px;
    min-width: 52px;
    flex-shrink: 0;
}

.ex-grid-head-col--action+.ex-grid-head-col--action,
.ex-grid-col--action+.ex-grid-col--action {
    margin-left: -8px;

    .ex-grid-col--action-link {
        padding-left: 8px;
    }
}

.ex-grid-col {
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: block;
    font-size: 12px;
    line-height: 2;
    text-align: left;

    ex-data-value {
        flex: 1;

        .ex-data-value-content {
            max-width: 100%;
            word-break: break-word;

            &.ex-data-value-type-bouton {
                display: flex;

                ex-menu-item {
                    flex: 1;
                }
            }
        }
    }

    &.ex-grid-col-edition-rapide,
    &.ex-grid-col-edition-rapide-deleted {
        padding-right: 0;
        padding-left: 10px;
    }

    &.ex-grid-col-edition-rapide-deleted {
        margin-bottom: 6px;
        margin-top: 6px;

        .ex-grid-col-value {
            display: inline;
        }
    }
}

.ex-grid-head-move-bouton {
    min-height: initial;
    min-width: initial;
    padding: 0 0;
    margin: 0 0;
    height: 100%;

    md-icon {
        color: rgb(255, 112, 67);
    }
}

.ex-grid-col-fixe {
    flex-shrink: 0;

    &.ex-grid-col-fixe--titres-lignes {
        font-size: 14px;
        font-weight: 500;
    }

    &:not(.ex-grid-col-fixe--titres-lignes) {
        background-color: $background-color-fixe;
    }
}

.ex-grid-row-content {
    border-top: 1px solid #ddd;
}

.ex-grid-row-more-content {
    border-top: 1px solid #ddd;
}

.ex-grid-row {
    &:not(.ex-grid-row--opened):hover {
        .ex-grid-row-content {
            background-color: $blue-grey-50;
        }

        .ex-grid-menu-actions {
            visibility: visible;
        }
    }
}

.ex-grid-row--active {
    font-weight: 600;
    background-color: $blue-grey-50;

    .ex-grid-menu-actions {
        visibility: visible;
    }
}

.ex-grid-row .ex-grid-row-content .ex-formulaire-input-champ-boolean {
    >div {
        width: 100%;
    }

    md-checkbox {
        margin: 0;

        >div.md-container {
            position: relative;
        }
    }

    .md-label,
    label {
        display: none;
    }
}

.ex-grid-row:not(.ex-grid-row--edition-rapide) .ex-grid-row-content .ex-formulaire-input-champ-boolean md-checkbox>div.md-container {
    top: initial !important;
}

.ex-grid-row--edition-rapide {
    position: relative;

    .ex-grid-col {
        &:not(.ex-grid-col-edition-rapide-deleted) {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    md-input-container {
        margin-bottom: 0 !important;
    }

    .md-label,
    label {
        display: none;
    }

    .ex-formulaire-input-champ {
        min-width: 0 !important;
        width: 100%;
    }

    .ex-grid-formulaire-input-container {
        position: static;
        height: auto;

        .ex-formulaire-input-champ {
            display: inherit;
        }
    }

    ex-menu-item {
        .md-icon-button {
            margin: 0;
        }
    }

    .ex-grid-menu-actions {
        visibility: visible;
    }

    .ex-grid-col--action {
        margin-top: 0 !important;
        padding-bottom: 10px !important;
    }

    ex-input-imputation .md-button.md-icon-button,
    .ex-input-lov-entite-externe-btn-modal.md-button.md-icon-button {
        height: 30px;
        min-height: 30px;
        padding: 0;
    }
}

.ex-grid-row--deleted {
    @extend .ex-strikethrough;
}

.ex-grid-row--opened {

    // Pour compenser la bordure
    .ex-grid-col:first-child {
        padding-left: 14px;
    }

    .ex-grid-col:last-child {
        padding-right: 14px;
    }

    .ex-grid-col {
        padding-top: 15px;
        font-weight: bold;
    }

    .ex-grid-menu-actions {
        visibility: visible;
    }
}

// Pour éviter les doubles bordures
.ex-grid-row--opened+.ex-grid-row--opened,
.ex-grid-row--opened:first-child {
    margin-top: -2px;

    .ex-grid-col {
        padding-top: 17px;
    }
}

.ex-grid-row--active .ex-imputation-data-type {
    padding-left: 4px;
}

.ex-imputation-data-type {
    padding-left: 8px;
}

.ex-grid-head-container {
    margin-top: auto;

    .ex-grid:not(.ex-grid--distribute-width) & {
        flex-grow: 0 !important;
    }
}

.ex-grid-head-col-fixe-titre-vide {
    opacity: 0;
}

.ex-grid-head-col,
.ex-grid-col {
    overflow: hidden;
}

.ex-grid-head-col-titre {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500 !important;
    padding-left: 5px;
    padding-right: 5px;
    color: #0097A7;
    font-size: 14px;

    >div {
        border-bottom: 1px solid #ddd;
    }
}

.ex-grid-head-col {
    .md-button {
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
        line-height: 0;
        min-height: inherit;
        min-width: inherit;
        padding: 5px 16px;
        margin: 0;
    }
}

.ex-grid-head-col-wrapper:not(.ex-grid-head-entete-wrapper) {
    .ex-grid-head-col {
        padding-top: 9px;
        box-sizing: border-box;
    }
}

.ex-grid-col-click {
    cursor: pointer;
}

.ex-grid-col-action-container {
    min-width: 52px;
    flex-shrink: 0;
}

.ex-grid-col-menu-forage {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.ex-grid-menu-actions {
    visibility: hidden;
    margin-top: 8px;
    min-width: 52px;

    .md-button {
        height: 100%;

        &.md-icon-button {
            display: inline-block;
        }
    }
}

.ex-grid-menu-actions--busy {
    visibility: visible !important;
}

.ex-grid-col--action {
    padding: 0 !important;
}

.ex-grid-loader {
    position: absolute;
    left: 50%;
    margin-left: -25px;
    margin-top: 130px;
}

.ex-grid-no-data {
    height: 100%;
}

.ex-grid-no-data-in-card {
    min-height: 240px;
    max-height: 240px;
}

.ex-grid-no-data-cercle {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    opacity: 0.4;
}

.ex-grid-no-data-icon {
    margin: 0;
    font-size: 48px !important;
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    color: #FFFFFF;
}

.ex-grid-col--action {

    ex-menu,
    md-menu {
        display: flex;
    }
}

.ex-grid-col--action-link {
    padding: 15px;
    height: 100%;
    display: block;
    box-sizing: border-box;
}

.ex-grid-col--action-bouton {
    height: 100%;

    .ex-grid-row--opened & {
        padding-top: 14px;
    }

    .ex-grid-row--opened:first-child & {
        padding-top: 17px;
    }
}

.ex-grid-col--action-menu {
    height: 100%;

    .md-button.md-icon-button {

        .ex-grid-row--opened & {
            padding-top: 14px;
        }

        .ex-grid-row--opened:first-child & {
            padding-top: 17px;
        }
    }
}

.ex-grid-checkbox {
    max-width: 18px;

    .ex-grid-head & {
        margin: 0 0 8px 15px !important;

        .md-container {
            width: 18px !important;
            height: 18px !important;
        }

        .md-icon {
            width: 18px !important;
            height: 18px !important;

            &:after {
                left: 4px !important;
                top: -1px !important;
            }

            .ex-setting-global-densite &:after {
                left: 3px !important;
                top: -2px !important;
                height: 12px;
            }
        }

        .md-container.md-ink-ripple {
            overflow: visible;
        }
    }

    .ex-grid-body & {
        margin-top: 15px;
        margin-left: 15px !important;

        .md-container {
            width: 18px !important;
            height: 18px !important;
        }

        .md-icon {
            border-color: $blue-grey-500;
            width: 18px !important;
            height: 18px !important;

            &:after {
                left: 4px !important;
                top: -1px !important;
            }

            .ex-setting-global-densite &:after {
                left: 3px !important;
                top: -2px !important;
                height: 12px;
            }
        }
    }
}

.ex-grid-menu-visible {
    .ex-grid-menu-actions {
        visibility: visible !important;
    }

    .ex-grid-row-more-content {
        .ex-grid-row:not(.ex-grid-row--active) .ex-grid-menu-actions {
            visibility: hidden !important;
        }
    }
}

.ex-grid-row-totaux {
    min-height: 56px !important;
    max-height: 56px !important;
    background-color: $background-color-fixe;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.ex-grid-col-totaux-fixe {
    flex: 0 !important;
}

.ex-grid-col-total {
    vertical-align: middle;
    line-height: 56px;
    text-transform: uppercase;

    &:not(.ex-grid-col-container) {
        text-align: right;
        min-width: 52px;
        max-width: 52px;
        flex-basis: 52px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.ex-grid-col-container {
        padding-left: 16px;
        box-sizing: border-box;
    }
}

.ex-grid-formulaire-input {
    display: block;
    width: 100%;
}

.ex-multi-occurrence-col-status {
    width: 52px;
    box-sizing: border-box;
}

.ex-grid-row-updated--statut-error {
    color: $red-500 !important;
    background-color: $red-50 !important;
}

.ex-grid-col-edition-assistee {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ex-grid-col-checkbox,
.ex-grid-head-col-checkbox {
    overflow: visible;
}

.ex-grid-col-menu-forage {
    .ex-grid-totaux-value .ex-data-value-container {
        margin-right: -16px;
    }
}

.ex-grid-hidden {
    display: none;
}

.ex-grid-not-hidden {
    display: block;
}

.ex-grid-col-setColorBlue {
    color: #1271CB !important;
}

.ex-grid-col-setColorRouge {
    color: #BF4A2D !important;
}

.ex-grid-col-setColorCell {
    background-color: #BF4A2D !important;
}