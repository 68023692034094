.ex-infos-financieres-tab-container {
    ex-multi-occurrence-advanced, ex-multi-occurrence {
        height: 95%;

        .ex-multi-occurrence-content {
            flex: 1;
        }

        ex-pagination {
            display: block;
        }
    }

    .ex-grid {
        display: flex;
        height: 100%;

        .ex-grid-head {
            border-bottom: none;
            padding-right: 18px;
            padding-left: 1px;
        }

        .ex-grid-body {
            overflow-y: scroll;
        }

        .ex-grid-row {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;

            &:last-of-type {
                border-bottom: 1px solid #ddd;
            }
        }

        .ex-grid-row-totaux {
            padding-right: 17px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
        }

        .ex-grid-row--opened + .ex-grid-row--opened, .ex-grid-row--opened:first-child {
            margin-top: 0;
        }
    }
}
