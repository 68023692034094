.dialog-lov-entite-externe {
    .ex-dialog-content {
        background-color: white;
    }
    .ex-dialog-div-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .ex-multi-occurrence-recherche-container {
        padding-left: 8px;
        padding-right: 8px;
    }
    .ex-grid-loader {
        margin-top: inherit;
    }
    min-width: inherit;

    md-dialog {
        min-width: 80%;
    }
}

.dialog-lov-entite-externe-md-dialog {
    min-width: 80%;
    .ex-multi-occurrence-content {
        height: auto !important;
    }

    ex-multi-occurrence {
        display: block !important;
    }
}
