ex-pieces-jointes {
    display: block;

    .ex-multi-occurrence-content {
        overflow: visible;
        flex: none;
    }

    .ex-pieces-jointes-ajout-rapide {
        .ex-saisie-header,
        .ex-saisie-actions {
            display: none;
        }

        ex-saisie {
            box-shadow: none;
            -webkit-box-shadow: none;
            margin: 0;
        }
    }

    ex-case {
        word-break: break-all;
    }
}

.ex-pieces-jointes-preview {
    position: fixed;
    right: 0;
    top: 160px;
    height: 100vh;
    padding-top: 48px;
    width: 35%;
    z-index: 58;

    >div {
        text-align: right;
        background-color: #eeeeee;
    }

    >embed {
        width: 100%;
        height: 100%;
    }

    >img {
        width: 100%;
        max-width: 100%;
    }
}

.ex-piece-jointes--clearfix {
    &:after {
        display: inline-block;
        content: '';
    }
}

.ex-pieces-jointes--saving-new-loader {
    z-index: 100;
    position: absolute;
    left: calc(50% - 50px);
}