.ex-input-intervalle.md-input-invalid {
    >fieldset {
        border-color: $red-500;

        >legend {
            color: $red-500;
        }
    }
}

.ex-input-intervalle>fieldset {
    border: 1px solid $black;
    border-radius: 5px 5px 5px 5px;
}