.ex-titre {
    font-size: 16px;

    &:not(.ex-titre--no-border) {
        border: 0px dotted;
        border-bottom-width: 1px;
    }
}

.ex-titre-icon {
    margin-left: 0;
    margin-right: 8px;
    margin-top: -4px;
}
