ex-card-multi-template {
    display: block;

    .ex-multi-occurrence-template-loader {
        margin-top: 30px;
        margin-bottom: 16px;
    }

    ex-multi-occurrence-template {
        width: calc(100% + 32px);
        margin-left: -16px;

        ex-multi-occurrence-template {
            width: auto;
            margin-left: 0;
        }

        ex-multi-occurrence-analyse & {
            width: auto;
            margin-left: 0;
        }
    }

    .ex-multi-occurrence-zone-recherche {
        padding-top: 0 !important;
    }

    md-card md-card-content {
        padding-bottom: 0 !important;
        padding-top: 0;
    }

    .ex-multi-occurrence-recherche {
        padding-bottom: 8px;
    }

    @media (min-width: 960px) {
        .ex-multi-occurrence-etats {
            padding-top: 16px;
        }

        .ex-multi-occurrence--has-filtres:not(.ex-multi-occurrence--has-etats) {
            .ex-multi-occurrence-zone-criteres {
                padding-top: 2px;
            }
        }

        .ex-multi-occurrence--has-etats:not(.ex-multi-occurrence--has-filtres) {
            .ex-multi-occurrence-etats {
                padding-bottom: 22px;
            }
        }
    }

    .ex-multi-occurrence-template-no-data {
        padding-top: 16px !important;
        margin-bottom: 9px;
    }

    ex-card-multi-template-row {
        display: block;
        width: 100%;

        ex-card-multi-template-row {
            display: none;
        }
    }

    .ex-multi-occurrence-recherche-container .ex-multi-occurrence-recherche {
        margin-right: 16px;
    }

    .ex-multi-occurrence--criteres-hidden {
        .ex-multi-occurrence-template-row:first-of-type {
            border-top-width: 0;
        }
    }

    .ex-multi-occurrence-template-row:first-of-type {
        transition: border-top-width 0.3s;
    }
}

.ex-card-multi-template-row-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4px;
}

.ex-card-multi-template-row-container span {
    grid-column: 1;
    margin-left: 20px;
}

.ex-card-multi-template-row-container-container-child {
    grid-column: 2;

}

.ex-card-multi-template-row-container-container-child > ex-case >.ex-case-content {
    padding-left: 0px;
    padding-right: 0px;
    font-weight: normal;
}