.ex-bouton-imputation-button {
    padding: 0 !important;
    margin: 0 !important;
    height: 0 !important;
    max-height: 0 !important;
    min-height: 20px !important;
}

.ex-bouton-imputation-icon {
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    line-height: 20px;
}
