.ex-calendrier {
    height: 100%;
    width: 100%;

    .fc-scrollgrid-section:not(.fc-scrollgrid-section-liquid) .fc-scroller {
        overflow: hidden !important;
    }
}

.fc-daygrid-event-harness a {
    height: 21px;
}

.fc-daygrid-event-dot {
    display: none;
}

.fc-event-title {
    padding: 0 !important;
}

.fc-daygrid-event .fc-event-time {
    margin: 0 4px;
}

.fc-h-event, .fc-v-event {
    border: none !important;
}

a.fc-daygrid-day-number {
    color: $black !important;
}

th.fc-col-header-cell {
    background-color: $grey-medium;

    a.fc-col-header-cell-cushion {
        color: $white;
        text-transform: capitalize;
    }
}

.ex-calendrier-month-title {
    text-transform: capitalize;
    font-weight: 500;
    padding-left: 8px;
    font-variant: all-petite-caps;
    font-size: 20px;
    margin-top: -8px;
}

.ex-calendrier-event {
    font-size: 12px;
    font-weight: 400;
    padding: 4px;
    margin: 2px;
}

.ex-calendrier-menu {
    min-height: 40px;

    ex-menu {
        color: $grey-medium;
    }
}

.ex-calendrier-calendar {
    display: inline-flex;
    padding-bottom: 8px;
    max-height: calc(100% - 40px);
    min-height: calc(100% - 40px);
}

.ex-calendrier-loader {
    z-index: 10;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.ex-calendrier-calendar--forage-on-events .ex-calendrier-event {
    cursor: pointer;
}

.fc-event {
    cursor: pointer;
  }

.ex-calendrier-menu-vue-selection {
    .ex-menu-bouton-texte {
        margin: 6px 8px;
        height: auto;
        font-size: 18px;
    }

    md-icon {
        margin-top: 6px !important;
    }
}

.hidden-time {
    display: none;
  }