ex-menu-item {
    button.md-button.md-icon-button,
    a.md-button.md-icon-button,
    span.md-button.md-icon-button {
        border-radius: 50%;
    }

    span.md-button.md-icon-button:not([disabled])>label {
        cursor: pointer;
    }

    .md-icon-button {
        display: flex;
        justify-content: center;

        i.far {
            font-size: 18px;
        }
    }

    &.ex-menu-item-compteur-below-before {
        .ex-menu-item-compteur {
            left: 4px;
            bottom: 1px;
            top: unset;
            right: unset;
        }
    }

    &.ex-menu-item-compteur-background-grey {
        .ex-menu-item-compteur {
            background-color: $blue-grey-500 !important;
        }
    }

    input[type="file"] {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        opacity: 0.0; /* Standard: FF gt 1.5, Opera, Safari */
        filter: alpha(opacity=0); /* IE lt 8 */
        -ms-filter: "alpha(opacity=0)"; /* IE 8 */
        -khtml-opacity: 0.0; /* Safari 1.x */
        -moz-opacity: 0.0; /* FF lt 1.5, Netscape */
    }
}

.ex-menu-item-visible {
    visibility: visible;
}

.ex-menu-item-compteur {
    color: $white;
    display: none;
    position: absolute;
    height: 16px;
    width: 16px;
    right: 6px;
    top: 4px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    pointer-events: none;
    z-index: 21;

    &.ex-menu-item-compteur-gauche {
        right: auto;
        left: 6px;
    }
}

.ex-menu-item--has-compteur {
    position: relative;

    .ex-menu-item-compteur {
        display: block;
    }
}

a.ex-menu-item-button[disabled],
a.ex-menu-item-button[disabled]:hover {
    color: $grey-secondaire !important;

    md-icon,
    i {
        color: $grey-secondaire !important;
    }
}

.ex-menu-item-button--courant {
    color: $black !important;
    cursor: default;
    background: transparent !important;

    md-icon,
    i {
        color: $black !important;
    }
}

.md-button.ex-menu-item-button--secondaire:not([disabled]) {
    color: $blue-grey-500 !important;

    md-icon,
    i {
        color: $blue-grey-500 !important;
    }
}

.ex-menu-item--menu-placeholder {
    height: 24px;
    width: 24px;
    padding: 4px;
}