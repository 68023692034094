.ex-select-colonne {
    width: 100%;
}

.ex-select-colonne-option {
    height: auto;
    min-height: 38px;
    .md-text {
        white-space: normal;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.ex-select-colonne-titre {
    font-size: 16px;
    text-transform: none;

}

.ex-select-colonne-no-margin {
    button {
        margin: inherit !important;
    }
}
