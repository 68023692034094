ex-menu-selection-specialisee {
    display: flex !important;
    flex-wrap: wrap;

    ex-erreur-chargement {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.ex-menu-selection-specialisee--img {
    padding-right: 4px;
    img {
        max-width: 24px !important;
        max-height: 24px !important;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
        display: inline-block;
    }
}

.ex-menu-selection-specialisee--loader {
    margin-top: 16px;
}
