md-input-container.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.9) !important;
}

md-input-container.md-input-focused label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.9) !important;
}

//Container invalide
md-input-container.md-default-theme.md-input-invalid label, md-input-container.md-input-invalid label, md-input-container.md-default-theme.md-input-invalid .md-input-message-animation, md-input-container.md-input-invalid .md-input-message-animation, md-input-container.md-default-theme.md-input-invalid .md-char-counter, md-input-container.md-input-invalid .md-char-counter {
    color: $red-500 !important;
}

md-input-container.md-input-invalid .md-input {
    border-color: $red-500 !important;
}

md-input-container.md-default-theme label.md-required:after, md-input-container label.md-required:after {
    color: $red-500 !important;
}
