.dialog-planification-employe {
    overflow: hidden;
    min-height: 300px;
    width: 1000px;

    .ex-dialog-content {
        background-color: $white;
        overflow-y: auto;
    }

    .ex-dialog-div-content {
        min-height: 250px;
    }

    ex-multi-occurrence {
        display: block !important;
        margin: 0 -8px;
    }

    md-dialog-actions {
        background-color: $white;
    }

    ex-multi-occurrence-template-row {
        flex: 1;
    }

    .ex-recherche-menu-actions-dans-entete {
        margin-right: -8px;

        .ex-recherche-menu-actions--menu-more {
            margin-right: 0;

            button.md-button.md-icon-button {
                padding: 8px 0;
                margin-right: -16px;
            }
        }
    }

    .ex-formulaire-container {
        .ex-formulaire-input-list-sous-groupe-conditionnel {
            .ex-formulaire-input-list-champ-container {
                display: inline-block;
                max-width: min-content;
            }
        }

        .ex-formulaire-input-list-champ-boolean {
            display: inline-block;
            max-width: min-content;
            min-width: 25px !important;
            padding-top: 24px;
        }
    }

    .ex-multi-occurrence-template-row {
        outline: none;
    }

    .ex-multi-occurrence-template-container-content {
        overflow: auto;
    }

    .ex-multi-occurrence-template-row--last {
        border-bottom: none;
    }
}

.ex-dialog-planification-employe-multi-occurrence-titre {
    padding-top: 8px;
    font-size: 20px;
}

.ex-dialog-planification-employe-col-avertissement {
    width: 24px;
}
