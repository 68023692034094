.ex-button-fab-selection {
    position: absolute;
    left: 60px;
    z-index: 50;
    // Corrige un bug d'affichage sur Firefox en créant un layer GPU
    transform: translate3d(0, 0, 0);

    // On affiche le bouton d'action comme un bouton "FAB"
    .ex-button-action {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 50%;

        md-icon {
            display: none;
        }
    }

    [ex-busy] {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        margin-left: -8px;
        margin-top: 0;
        display: block;
    }

    md-progress-circular {
        display: block !important;
        float: none !important;
    }

    .ex-button-action:disabled,
    .ex-button-action:not(:disabled),
    .ex-button-action.md-button:not(:disabled) {
        color: #fff !important;
    }

    // Durant le chargement, on cache l'icône pour rendre le loader visible
    .ex-button-multi--busy {
        .ex-button-multi-libelle {
            visibility: hidden;
        }
    }

    .ex-button-multi {
        transition: transform .35s;
        transform-style: preserve-3d;
        display: block;
        position: relative;
    }

    .ex-button-fab-selection-icon, .ex-button-fab-selection-clear {
        backface-visibility: hidden;
        position: absolute;
        top: 8px;
        left: 8px;
        transition: transform .35s;
    }

    .ex-button-fab-selection-icon {
        transform: rotateY(0deg);
    }

    .ex-button-fab-selection-clear {
        // On positionne l'icône derrière le bouton
        z-index: -1;
        transform: translateZ( -1px );
    }

    .ex-button-multi--opened {
        transform: rotateY(180deg);

        .ex-button-fab-selection-icon {
            transform: rotateY(180deg);
            backface-visibility: hidden !important;
            display:none
        }

        .ex-button-fab-selection-clear {
            backface-visibility: visible !important;
            display: unset;
        }
    }

    &.ex-button-multi-container--opened {
        z-index: 110 !important;
    }
}
