ex-erreur-chargement {
    display: block;
}

.ex-erreur-chargement[layout=row] {
    white-space: nowrap;
}

.ex-erreur-chargement-icon {
    color: $red-500;
}

.ex-erreur-chargement-message {
    color: $red-500;
    text-align: center;
}

button.md-button.md-primary.md-raised.ex-erreur-chargement-action {
    @extend .ex-blue-grey-400-bg;
    white-space: nowrap;

    &:not([disabled]):hover, &:not([disabled]):focus {
        @extend .ex-blue-grey-600-bg
    }
}
