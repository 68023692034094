.ch0001-dialog-ajout-employe {
    min-width: 60%;

    .ex-formulaire-input-list-sous-groupe-conditionnel {
        .ex-formulaire-input-list-champ-container {
            display: inline-block;
            max-width: min-content;
        }
    }

    .ex-formulaire-input-list-champ-boolean {
        display: inline-block;
        max-width: min-content;
        min-width: 25px !important;
        padding-top: 24px;
    }
}

.ch0001-multi-occurrence-grid {
    .ex-data-value-content {
        white-space: normal !important;
    }
}

.ch0001-bouton-selection-periode {
    .ex-menu-bouton-texte {
        text-transform: none !important;
    }
}

.ch0001-bouton-icon-periode {
    margin-left: 2px !important;
}

.ch0001-periodes-loader {
    position: absolute;
    left: 13%;
}
