ex-multi-occurrence-advanced {
    display: block;

    // Il y a en général un padding pour le scroll bar, mais ici le scroll est dans la page
    @media (min-width: 960px) {
        .ex-card-content & ex-multi-occurrence .ex-grid-head,.ex-card-content & ex-multi-occurrence .ex-grid-row-totaux {
            padding-right: 0;
        }
    }

    .ex-multi-occurrence-advanced-ajoute-sans-recherche {
        margin-top: 16px;
    }

    .ex-multi-occurrence-content {
        flex: none;
    }

    .ex-multi-occurrence-advanced--has-inline-multi {
        .ex-saisie-content {
            overflow: hidden;
        }
    }

    .ex-multi-occurrence-actions {
        margin-bottom: 8px !important;
    }

    .ex-grid {
        display: block;
    }

    .ex-grid-head {
        box-shadow: none !important;
    }

    .ex-card-content & ex-multi-occurrence .ex-grid .ex-grid-body {
        display: block;
        overflow: auto;
        max-height: none;
        flex: none;
    }

    md-content {
        overflow: visible;
        height: auto;
    }

    .ex-grid-row {
        display: block;
    }

    ex-erreur-chargement {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    // Lorsque le multi-occurrence advanced est imbriqué dans un autre multi-occurrence
    ex-multi-occurrence & {
        .ex-grid-no-data-cercle {
            display: none;
        }

        .ex-multi-occurrence-content {
            padding-left: 0;
            margin-left: 0;
        }

        .ex-grid-loader {
            position: relative;
            margin-top: 24px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
        }

        .ex-erreur-chargement {
            margin-top: 8px;
            margin-bottom: 16px;
        }
    }

    .ex-grid-row--opened {
        ex-pieces-jointes {
            .ex-multi-occurrence-template-container {
                padding: 0 16px;
            }

            .ex-grid-menu-actions {
                visibility: hidden !important;
            }
        }
    }
}

.ex-multi-occurrence-advanced-loader {
    margin: 16px auto;
}

.ex-grid-row {
    &:hover {
        .ex-multi-occurrence-advanced--inline-details {
            ex-menu.ex-recherche-menu-actions--menu-more md-icon:not(:hover) {
                @extend .ex-blue-grey-500;
            }
        }
    }

    .ex-multi-occurrence-advanced--inline-details, ex-template {
        ex-multi-occurrence-advanced, ex-grid.layout-fill {
            margin: 0 !important;
            width: 100% !important;
        }

        ex-card-multi-advanced {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .ex-multi-occurrence-content {
            flex: none !important;
        }

        .ex-grid {
            display: block;
            height: auto;

            .ex-grid-body {
                display: block;
                flex: none;
                height: auto;
            }

            .ex-grid-row {
                border-left: none;
                border-right: none;

                &:last-of-type {
                    border-bottom: none;
                }
            }

            .ex-grid-row:not(.ex-grid-row--opened):not(.ex-grid-row--active) {
                font-weight: normal;

                .ex-grid-menu-actions {
                    visibility: hidden;
                }

                .ex-grid-col {
                    font-weight: normal;
                }

                &:hover {
                    .ex-grid-menu-actions {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.ex-multi-occurrence-advanced-details-template {
    display: flex;
    flex-direction: column;

    > ex-template {
        display: flex;
        flex-direction: column;
    }
}
