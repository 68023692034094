.ex-vertical-navigation-spinner-forage {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.5);

    >md-progress-circular {
        margin-left: auto;
        margin-right: auto;
        top: calc(50vh - 75px);
    }
}
