ex-multi-occurrence-hierarchie {
    position: relative;
    display: block;
}

.ex-multi-occurrence-hierarchie-loader {
    z-index: 1;
    position: absolute;
    left: calc(50% - 50px);
    top: 40px;
    margin-bottom: 16px;
}

.ex-multi-occurrence-hierarchie-no-data {
    margin-bottom: 24px;
}

.ex-multi-occurrence-hierarchie-no-data-cercle {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    opacity: 0.4;
}

.ex-multi-occurrence-hierarchie-no-data-icon {
    margin: 0;
    font-size: 48px;
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    color: #FFFFFF;
}

.ex-multi-occureence-hierarchie-recherche-parent {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.ex-multi-occureence-hierarchie-recherche {
    width: 60%;
    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 12px;
    padding-bottom: 8px
}