.ex-card-selection-employe {
    font-size: 18px;
    font-weight: normal;

    md-icon {
        font-size: 36px;
        color: white;
    }

    button.md-button.ex-button {
        float: right;
    }

    >span {
        display: inline-flex;
        align-items: center;
    }
}
