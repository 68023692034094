.ex-infos-complementaires-row {
    cursor: pointer !important;
    margin-top: inherit !important;
    flex: 1 1 32%;
    max-width: 32.33%;
    outline: none;
}

.ex-infos-complementaires-row-input-container {
    width: 100%;
}

ex-infos-complementaires {
    .ex-accordeon-content {
        padding-top: 16px !important;
        padding-bottom: 0px !important;
    }
}

.ex-infos-complementaires-row-input-container:active,
.ex-infos-complementaires-row-input-container:focus,
.ex-infos-complementaires-row:active {
    outline: none;
}

.ex-infos-complementaires-row-valeur {
    padding-top: 6px;
    border-bottom: solid rgba(0, 0, 0, 0.12) 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    box-sizing: border-box;
    min-height: 27px;
}

