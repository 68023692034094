.ex-dialog-imputation {
    display: block;
    min-width: 500px;
    max-width: 500px;

    .ex-dialog-container {
        display: block;
        flex: 1;
    }

    md-dialog-content {
        display: block;
        overflow: hidden;
        flex: 1;
    }
}

.ex-dialog-imputation-loader {
    left: 50%;
    margin-left: -25px;
    margin-bottom: 16px;
}

.ex-dialog-imputation-form {
    opacity: 0;
}

.ex-dialog-imputation-form-visible {
    opacity: 1;
}

.ex-dialog-imputation-lov-obligatoire {
    // On ne veut pas ajouter le validator required pour permettre l'autocomplétion dans l'imputation,
    // donc on "simule" une lov obligatoire.
    .ex-input-lov-input-wrapper label:after {
        content: ' *';
        font-size: 13px;
        vertical-align: top;
        color: $red-500 !important;
    }
}
