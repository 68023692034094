ex-data-value + ex-accordeon {
    margin: 12px 8px;
}

ex-accordeon {
    display: block;

    &:not(:last-child) {
        .ex-accordeon-container {
            margin-top: 8px;
        }
    }

    .ex-expandable--collapsed {
        ex-accordeon-actions {
            display: none;
        }
    }

    .md-button.md-icon-button {
        border-radius: 50%;
    }
}

.ex-accordeon-container:not(.ex-expandable--expanded):not(.ex-expandable--collapsed) {
    .ex-accordeon-content {
        display: none;
    }
}

.ex-accordeon-content {
    @extend .p-t-8, .p-b-8;
}

.ex-accordeon-disabled {
    cursor: default !important;
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.38);
}

.ex-accordeon {
    cursor: pointer;
    border-radius: 5px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;

    .ex-card-content & {
        padding-left: 10px;
        padding-right: 8px;
        width: 100%;
        display: block;
        box-sizing: content-box;
        margin-left: -9px;
    }

    button {
        &:focus md-icon {
            color: rgba(0, 0, 0, 0.54) !important;
        }
    }
}

.ex-accordeon-actions {
    min-height: 24px;
    max-height: 24px;
    line-height: 24px;
}

ex-accordeon-header {
    flex-direction: row;
    display: flex;

    md-icon {
        color: #fff;
    }
}

.ex-accordeon-actions:empty {
    display: none;
}

.ex-accordeon-actions {
    .md-button:not(.ex-recherche-menu-actions-edition-rapide-fab-button):not(.ex-recherche-menu-actions-edition-rapide-fab-action) {
        @extend .ex-smaller-button;
    }

    .md-icon-button:not([disabled]):not(:focus):not(:hover) md-icon,
    .md-button.ex-button-icon-action:not([disabled]):not(:focus):not(:hover) md-icon,
    .md-button.md-icon-button.ex-button-icon-action.ex-bouton-nouveau:not([disabled]):not(:focus):not(:hover) md-icon {
        color: #fff !important;
    }

    .md-button,
    .ex-bouton-nouveau.md-button:not([disabled]):not(.ex-disabled--disabled) {
        &:focus {
            md-icon {
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
    }

    &:focus {
        outline: none;
    }

    &:not(.md-button):hover {
        cursor: default;
    }
}

.ex-accordeon-bouton {
    margin-right: -2px !important;
}

.ex-accordeon-group div {
    margin-top: 5px;

    &:first-of-type,
    &:last-of-type {
        margin-top: 0 !important;
    }
}

.ex-accordeon-actions {
    .ex-recherche-menu-actions-dans-entete {
        ex-menu.ex-recherche-menu-actions--menu-more {
            margin-right: 0;
        }
    }
}
