.dialog-cycle-transactionnel-informations {
    min-width: 500px !important;

    ex-cycle-list-etapes {
        margin-top: 15px;
    }

    ex-data ex-data-valeur>ex-menu-item * {
        padding: 0 !important;
        margin: 0 !important;
        line-height: inherit !important;
        width: 25px !important;
        height: 25px !important;
        min-height: 0px !important;
        display: inline-block;

        md-icon > span {
            vertical-align: middle;
        }
    }

    ex-data .ex-data-label {
        margin-top: auto;
        margin-bottom: auto;
    }

    ex-data-valeur {
        color: $black;
    }
}
