ex-selecteur {
    display: block;
    user-select: none;
}

.ex-selecteur-box {
    border: 2px solid $blue-grey-500;
    border-radius: 4px;
    padding: 8px;
}

.ex-selecteur-row {
    outline: none;
    cursor: grab;
    border-radius: 10px;
    color: $white;
    background-color: $blue-grey-400;

    md-icon span {
        color: $white;
    }
}

.ex-selecteur-header-right {
    padding-left: 34px;
}

.ex-selecteur-separator {
    position: relative;
    padding-bottom: 40px;
}

.ex-selecteur-separator-bar {
    border-right-style: solid;
    border-width: 2px;
    border-color: $blue-grey-500;
    position: absolute;
    height: 100%;
    padding-left: 18px;
}

.ex-selecteur-separator-action-add,
.ex-selecteur-separator-action-rm {
    margin: 0px;
    margin-bottom: 5px;
}
