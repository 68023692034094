ex-case-description {
    display: block;
    clear: both;

    .ex-case-content {
        white-space: pre-wrap;
    }
}

.ex-case-description--secondaire {
    font-size: 12px;
}
