.ex-dialog-recuperation-dialog {
    overflow: hidden;
}

.dialog-recuperation {
    width: calc(100vw - 32px) !important;
    max-width: 1200px;
    height: calc(100vh - 32px) !important;

    .ex-dialog-content, .ex-dialog-div-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 100%;
        background-color: $white;
    }
    .ex-dialog-div-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    md-dialog-content {
        overflow: hidden;
    }

    ex-multi-occurrence-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: 100%;
    }

    ex-multi-occurrence-advanced .ex-grid {
        display: flex;
    }

    .ex-multi-occurrence-content {
        //overflow: auto;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
    }

    ex-multi-occurrence ex-pagination {
        padding-top: 12px;
        padding-bottom: 12px;
        border-top: 1px solid #ddd;
    }

    ex-grid {
        flex-direction: column;
        display: flex;
    }

    .ex-grid {
        flex: 1;
    }

    .ex-grid-body {
        border-bottom: 0;
        flex: 1;
    }

    .ex-grid-body {
        display: flex;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.dialog-recuperation-loader {
    position: absolute;
    left: 50%;
    margin-top: 40px;
    margin-left: -25px;
    margin-bottom: 16px;
}
