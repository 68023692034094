ex-input-string {
    display: flex;

    // Permet de fixer un bogue de la ligne en point ne s'affiche pas avec l'état disabled
    md-input-container .md-input[disabled], [disabled] md-input-container .md-input {
        background-position: bottom 0px left 0;
    }

    ng-messages {
        display: flex;
    }
    //Empêche le bug où deux messages sont affichés au même moment, ce qui
    //cancel l'animation du second message, le rendant invisible.
    ng-message + ng-message {
        display: none;
    }

    input[readonly], input[readonly][disabled] {
        border-bottom: 0;
        color: $black;
    }

    &[readonly], &[readonly][disabled] {
        // Remonte le libellé, comme lorsqu'il y a des données
        label {
            color: rgba(0, 0, 0, 0.54);
            transform: translate3d(0, 6px, 0) scale(0.90) !important;
            transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s !important;
        }

        label:after {
            display: none;
        }
    }

    .ex-introspection-button {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.ex-input-string {
    width: 100%;
}

.ex-input-string-count-disabled {
    .md-char-counter {
        display: none;
    }
}